/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;600;700;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

* {
  // font-family: 'DM Sans', sans-serif !important;
  user-select: none;
  font-family: 'Montserrat', sans-serif;
}

*,
:after,
:before {
  box-sizing: border-box;

  // this for firefox
  scrollbar-width: none;
}


body {
  -webkit-font-smoothing: none;


}

@-moz-document url-prefix() {

  /* Disable scrollbar Firefox */
  html {
    scrollbar-width: none;
  }
}

::-webkit-scrollbar {
  padding: 0px;
  width: 0;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border: 1px solid transparent;
  border-width: 3px;
  border-radius: 30px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 1px solid transparent;
  width: 0;
  display: table-cell;
  vertical-align: middle;
  border-radius: 30px;
}

:root {

  --ion-theme-color: #161C23;
  --ion-theme-border-color: #293138;
  --ion-select-color: #29313A;
  --ion-btn-theme: #1E56E7;
  --ion-white-text: #fff;
  --ion-text-light-color: #939eab;
  --ion-mobile-bg: #fff;
  --ion-inner-bg: #0D1116;
  --ion-text-black: #000;
  --color: #fff;
  --ion-icon-color: #8A9097;

  --ion-success-color: #009a4d;
  --ion-warning-color: #0080FF;
  --ion-failed-color: #ff0000;

  --extra-height: 937px;

  --countdown_timer_color: #000;


  --app-primary-color: #0080FF;
  --app-secondary-color: #E6FFF9;
  --app-cart-color: #00A329;
  --app-menu-color: #000;


  --color-black: #000;
  --color-white: #fff;
  --color-gray-100: #e6e6e6;
  --color-gray-200: #b1b8c9;
  --color-gray-300: #656c7c;
  --color-primary-100: #b3cdff;
  --color-primary-200: #1769ff;
  --color-secondary-100: #ffecf0;
  --color-secondary-200: #ea4c89;
  --color-accent: #fdb72e;

  --content-p-block: 4rem; // Content padding top & bottom (64px)
  --content-p-inline: 1rem; // Content padding left & right (16px)
  // 1128px content width + padding outside on both sides 👇
  --content-max-width: calc(70.5rem + calc(var(--content-p-inline) * 2));

  --font-family: Inter, sans-serif;
  --font-color: var(--color-gray-300);
  --line-height: 1.5;
  --body-bg-color: var(--color-white);

  --text-base: 1rem; // 16px
  --text-md: 1.5rem; // 24px
  --text-lg: 2rem; // 32px

  --rounded-sm: 0.5rem; // 8px
  --rounded-lg: 1rem; // 16px
  --rounded-full: 9999px;

  --transition-fast: 100ms ease-in-out;
  --border-sm: 0.125rem; // 2px
  --shadow-md: 0px 0.875rem 1.875rem rgba(204, 204, 204, 0.32); // 0 14px 30px

  --border_color: #ffffff30;
  --text-light-color: #acafb4;

}

.ion-color-primary {
  --ion-color-base: #00ff80 !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: var(--ion-white-text) !important;
  font-family: 'Questrial', sans-serif !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  font-family: 'Questrial', sans-serif !important;
}

ion-button {
  color: var(--ion-white-text);
}

ion-icon {
  color: var(--ion-white-text);
  cursor: pointer;
}

input {
  color: var(--ion-white-text) !important;
}

.ion-color-light {
  --ion-color-shade: transparent !important;
  --ion-color-contrast: transparent !important;
}

.menu-content-open {
  pointer-events: unset !important;
}

.width-100 {
  width: 100%;
}

.ml-20 {
  margin-left: 20px !important;
}

.mt-8px {
  margin-top: 8px !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

/* App Colors */

.primary_text_color {
  color: var(--app-primary-color) !important;
}

.secondary_text_color {
  color: var(--app-secondary-color) !important;
}

.cart_text_color {
  color: var(--app-cart-color) !important;
}

.menu_text_color {
  color: var(--app-menu-color) !important;
}

.primary_bg_color {
  background-color: var(--app-primary-color) !important;
}

.secondary_bg_color {
  background-color: var(--app-secondary-color) !important;
}

.cart_bg_color {
  background-color: var(--app-cart-color) !important;
}

.menu_bg_color {
  background-color: var(--app-menu-color) !important;
}

/* App Colors */

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {

  color: var(--ion-white-text);

}

.drag_item {
  position: absolute !important;
  z-index: 999;
  height: 100% !important;
  width: 95% !important;
  top: 0;
  left: 0;
  border-radius: 6px;
  margin: 0 auto;
}

p,
ion-label,
ion-label {
  color: var(--ion-white-text) !important;
  letter-spacing: 0.5px;
}

.element_img_screen {

  opacity: 1 !important;

}

app-file-uploader {
  width: 10px;

  input {
    width: 10px;
  }
}

.pwd_valid {
  background: #1e56e717;
  padding: 10px;
  color: var(--ion-failed-color);
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  letter-spacing: 0.6px;

  p {
    color: var(--ion-failed-color) !important;
    letter-spacing: 0.6px;
  }
}


ion-popover {
  &::part(content) {
    border-radius: 0px;
  }

  &.table_menu {
    ion-content {
      &::part(background) {
        border-radius: 0px;
        background: var(--ion-theme-color);
      }

      .btn_sec_modal {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      ion-button {
        width: 100%;
        height: 50px;
        margin: 0 !important;
        --box-shadow: unset;
        text-transform: capitalize;
        font-size: 14px;
        --background: var(--ion-btn-theme);
        --border-radius: 4px;

        ion-icon {
          margin-right: 15px;
        }
      }
    }
  }

}

.validation_msg {
  color: var(--ion-failed-color) !important;
  font-size: small;
  margin-top: 10px !important;
  letter-spacing: 0.6px !important;
}

.agree-label {
  .valid_msg {

    background: #1e56e717;
    padding: 10px;
    color: var(--ion-failed-color) !important;
    margin-top: 10px;
    width: 100%;
    font-size: 14px;
    letter-spacing: 0.6px;

  }
}

app-product-upload {
  .block {
    animation-fill-mode: unset;
  }
}

app-form-errors {
  width: 100%;

  .hide-err {
    color: var(--ion-failed-color);
    margin-top: 10px;
    letter-spacing: 0.6px;
  }
}

app-dropdown {
  &.new_modal_drop_down {
    .dropdown_component_sec {
      margin-top: 10px;
    }
  }

  .dropdown_component_sec {
    font-size: 12px;
    height: 38px;
    border: 1px solid #dadada;
    background-color: rgba(255, 255, 255, 0.0196078431);
    color: var(--ion-white-text);
    border-radius: 4px;
    text-indent: 12px;
    caret-color: unset !important;

    ng-select {
      height: 100%;

      // &.ng-select-filtered {
      //   .ng-input {
      //     position: relative !important;
      //   }
      // }

      &.ng-select-filtered.ng-dirty {
        .ng-input {
          // position: absolute !important;
          top: 15px;
        }
      }

      .ng-select-container {
        height: 100%;
        align-items: center;
        padding: 0 15px 0 15px;

        // .ng-placeholder {
        //   text-indent: 10px;
        // }

        // .ng-value {
        //   width: 100%;
        // }
        input {
          text-indent: 15px;
        }

        .ng-input {
          left: 10px;
          width: auto !important;
          // position: relative !important;
        }
      }
    }
  }
}

app-search {
  ion-searchbar {

    --background: var(--ion-inner-bg);
    --box-shadow: unset;

    input {
      color: var(--ion-text-light-color) !important;
    }
  }
}

.save_update {
  ion-button {
    --background: var(--ion-success-color) !important;
  }
}

.preview {
  ion-button {
    --background: var(--ion-mobile-bg) !important;
    color: var(--ion-text-black);

    span {
      color: var(--ion-text-black);
    }
  }
}

.cancel {
  ion-button {
    border: 1px solid var(--ion-btn-theme) !important;
    border-radius: 4px !important;
    --background: var(--ion-btn-theme) !important;
  }
}

.btns {
  app-button {
    ion-button {
      width: 100%;
      height: 100%;
      --background: var(--ion-btn-theme);
      --box-shadow: unset !important;

      &::part(native) {
        border-radius: 3px !important;
        min-width: 80px;
        padding: 6px 8px;
        height: 40px;
        font-size: 14px;
        letter-spacing: 0.5px;
        margin-right: 10px;
        text-transform: capitalize;
        font-weight: 500;
      }
    }
  }
}

.btn-cart span {
  background: #e8e8e8;
  width: 0.8em;
  height: 0.8em;
  display: block;
  margin: 0 0.5em;
  border-radius: 100px;
  margin-top: -18px;
}

::-webkit-scrollbar {
  display: none !important;
}

.show_items_preview {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 140px;
  width: 390px;
  margin: 0 auto 80px auto;

  .device_preview,
  .preview_items {
    width: auto;
    height: auto;
    position: relative;

    // .custom-placeholder {
    //   display: none;
    // }
  }

  .preview_items {
    overflow: scroll;
  }

  .preview_boundary {
    width: 370px;
    min-height: 720px;
    height: 100%;
    max-width: 100%;
    margin: 40px 0px;
    text-align: center;
    overflow-y: scroll;
    background: var(--ion-mobile-bg);
    position: relative;
    // padding: 4px;

    &.cdk-drop-list-dragging {
      .preview_item:last-child {
        margin-bottom: 500px;
      }
    }

    .preview_body {
      .showcase_sec {
        background: url("assets/img/element/theme_screen.png") no-repeat;
        height: 570px;
        background-size: cover !important;

        &.PRODUCT {
          background: url("assets/img/element/product_img.svg") no-repeat;
        }

        &.CART {
          background: url("assets/img/element/cart_img.svg") no-repeat;
        }
      }
    }

    .cdk-drag-placeholder {
      top: 0;
      position: relative;
    }

    .cdk-drag-placeholder {
      width: 100% !important;
      height: 50px !important;
    }

    .preview_box {
      width: 100%;
      height: auto;
      // border: solid 1px #f1f1f1;
      color: #1e1e1e;
      cursor: move;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: #fff;
      margin: 0 auto;
      box-sizing: border-box;
      // padding: 5px;
      // padding-bottom: 20px;
      border: 2px solid #fff0 !important;
      // margin-bottom: 30px;

      &.selected {
        border: 2px solid var(--ion-btn-theme) !important;
      }
    }

  }

  .preview_items::-webkit-scrollbar {
    display: none;
  }

  .preview_boundary::-webkit-scrollbar {
    display: none;
  }

  .preview_box:active {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
}

app-showcase {
  .preview_boundary {
    padding-bottom: 50px !important;
  }
}

app-color-preview {
  .show_items_preview {
    margin: 0;

    .preview_boundary {
      min-height: 720px !important;
      position: relative;

      .action_btn_item {
        position: absolute;
        bottom: 10px;
        width: 100%;
        height: auto;

        .btn-cart {
          width: 94%;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 7px;
          margin: 0 auto;
          font-size: 14px;
        }
      }
    }
  }
}

// Above design is vigneash dev

/* common design */

.w-100 {
  width: 100% !important;
  margin: 0 auto !important;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.h-100 {
  height: 100%;
}

.no-margin {
  margin: 0 !important;
}

.padding-top-15 {
  padding-top: 15px;
}

p {
  font-size: 11px;
  color: var(--ion-white-text);
}

.d_flex {
  display: flex;
}

.flex-column {
  flex-direction: column !important;
}

.justify-content-center {
  justify-content: center;
}

.justify_content_center {
  justify-content: center !important;
}

.justify_content_btw {
  justify-content: space-between !important;
}

.align-items {
  align-items: center;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start !important;
}

.center_content {
  height: calc(100% - 70px);
  padding-top: 40px;
}

.img_preview {
  width: 300px;
  height: 750px;
}

.col-lg-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.phone {
  width: 200px;
  height: 399px;
  min-height: 360px;
  position: relative;
}

.phone_box {
  ion-img {
    &.app-icon {
      height: 100%;
      width: 90%;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0 auto;
      right: 0;
      bottom: 0;

      &::part(image) {
        border-radius: 20px;
      }
    }
  }
}

.app-icon,
.text {
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 0;
  top: 175px;
  right: 25px;
}

.bg {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 100;
}

.text {
  top: 206px;
  color: #f2eefc;
  z-index: 99999;
  font-size: 5px;
  font-weight: 400;
  right: 18px;
  text-align: center;
  width: 44px;
  word-break: break-word;
}

.phone-behind-text {
  top: 100%;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  color: var(--ion-white-text);
  padding-top: 15px;
  text-align: center;
  position: absolute;
}

.color-picker-form-group {
  padding: 5px 0;
  height: auto;

  .picker-holder {
    width: 57px;
    height: 30px;
  }
}

.color-picker-container {
  padding: 3px;
  width: 100%;
  height: 100%;
  border: 1px solid #d7d9e4;
  position: relative;
  cursor: pointer;

}

.icon-picker-form-group {
  padding: 18px 0 22px;
  min-height: 101px;

  ul {
    &.iconHolder {
      list-style: none;
      margin: 0;
      padding: 14px 0 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }
}

/* common design */


/* common design for component */
.text_sec {
  h6 {
    border: 1px solid var(--ion-btn-theme);
    padding: 10px;
    font-size: 14px;
  }
}

app-toggle {
  h6 {
    border: 1px solid var(--ion-btn-theme);
    padding: 10px;
    font-size: 14px;
  }

  ion-toggle {
    --handle-width: 20px;
    --handle-height: 25px;

    &.toggle-checked {
      &::part(track) {
        background: #1e56e7b3;
      }

      &::part(handle) {
        background: var(--ion-btn-theme);
      }
    }

    &::part(track) {
      width: 35px;
      height: 14px;
      background: rgb(123 126 130);
    }
  }
}

app-color-editor {
  width: 100%;
}

app-dropdown {
  .dropdown_comp {
    ion-list {
      font-size: 12px;
      height: 38px;
      border: 1px solid #dadada;
      background-color: rgba(255, 255, 255, 0.0196078431);
      color: #ffffff;
      border-radius: 4px;
      text-indent: 12px;
      padding: 0 10px;

      ion-item {
        --min-height: auto;
        --background: transparent !important;
        --border-color: unset;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        --padding-start: 0;
        --inner-padding-end: 0;
        --inner-border-width: 0;
        --highlight-height: 0;

        ion-select {
          // min-height: 100%;
          height: 38px;

          &::part(placeholder) {
            width: 100vw !important;
            color: var(--ion-white-text);
          }

          &::part(text) {
            width: 100vw !important;
            color: var(--ion-white-text);
          }
        }
      }
    }
  }
}

app-providers-modal {
  app-dropdown {
    ion-list {
      padding: 0 !important;

      ion-item {
        height: 100%;
        --padding-start: 8px;

        ion-select {
          height: 38px;
        }
      }
    }
  }
}

app-settings {

  .showcase_page {
    .center_side {
      width: 80%;
      margin: 0 0 0 auto;
    }
  }

  .settings_editor_sec {
    height: 100%;

    app-settings-preview {
      .image_container {
        height: 100%;
        align-items: center;
        justify-content: center;

        .image_wrapper {
          padding: 0px 20px;
        }
      }
    }
  }
}

app-button {
  width: 100%;

  ion-button {
    width: 100%;
    --box-shadow: unset;

    &::part(native) {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-transform: capitalize;
    }
  }
}

.delete_sec {
  app-button {
    ion-button {
      &::part(native) {
        background-color: #b90014;
      }
    }
  }
}

/* common design for component */



/* icon design*/

.icon_sec {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 5px;
  cursor: pointer;

  // &:hover {
  //   background: var(--ion-select-color);
  // }

  ion-icon {
    width: 20px;
    height: 20px;
  }


}

/* icon design*/

/* menu */
ion-menu {
  width: 90px;
  border-right: 1px solid var(--ion-theme-border-color);
  --side-min-width: 90px;

  &::part(container) {
    width: 100%;
    background: var(--ion-theme-color);
  }

  ion-header {
    .menu_logo {
      height: 70px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      ion-icon {
        width: 40%;
        height: 100%;
        margin: 0 auto;
      }
    }

    &::after {
      content: "" !important;
      width: 83% !important;
      background-color: var(--ion-theme-border-color);
      height: 2px !important;
      margin: 0 auto !important;
      position: absolute !important;
      left: 10% !important;
      bottom: unset !important;
    }
  }

  ion-content {
    &::part(background) {
      width: 100%;
      background: var(--ion-theme-color);
    }

    .menu_list {
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 20px;
    }

    ion-list {
      height: 100%;
      background: transparent !important;

      ion-item {
        --background: transparent !important;
        padding: 10px 0;

        &.active {
          background-color: var(--ion-btn-theme);

          .icon_sec {
            ion-icon {
              color: var(--ion-white-text);
            }
          }

          .icon_sec {
            &:hover {
              background: transparent;

              ion-icon {
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7499%) hue-rotate(257deg) brightness(102%) contrast(104%);
              }
            }
          }
        }

        .icon_sec {
          width: 50px;
          height: 90%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          border-radius: 5px;
          cursor: pointer;

          ion-icon {
            color: var(--ion-icon-color);
          }

          &:hover {
            background: var(--ion-select-color);

            ion-icon {
              color: var(--ion-white-text);
            }
          }

          &.selected {
            background: var(--ion-btn-theme);

            ion-icon {
              color: var(--ion-white-text);
            }
          }

          ion-icon {
            width: 25px;
            height: 25px;
          }


        }
      }
    }
  }


}

/* menu */

/* header section */



.header_sec {

  &.menu-content {
    &::after {
      content: unset;
    }

  }

  &::after {
    content: "";
    height: 1px;
    width: calc(100% + 40px);
    background: var(--ion-theme-border-color);
    margin-top: 15px;
    margin-left: -15px;
    display: block;
  }

  .tittle_sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;

    p {
      font-size: 13px;
      margin: 0;
    }

    ion-icon {
      font-size: 24px;
    }
  }

  ion-header {
    background: var(--ion-theme-color);
    width: 100%;
    height: 70px;
    border-bottom: 1px solid var(--ion-theme-border-color);
    overflow: hidden;
    box-shadow: unset !important;

    &::after {
      content: unset;
    }

    app-header {
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        height: 100%;

        .left,
        .right {
          width: 280px;
        }

        .center {
          width: 40%;
        }

        .left {

          .back_container {
            display: flex;
            align-items: center;

            .back_btn {
              padding-right: 25px;

              ion-icon {
                font-size: 30px;
              }
            }

            .header_title {
              p {
                margin: 0 0 0;
                font-size: 18px;
              }

              h6 {
                margin: 0;
                font-size: 12px;
                color: var(--ion-text-light-color);
              }
            }
          }
        }

        .center {
          display: flex;
          align-items: center;
          justify-content: center;

          .mobile_orientation {
            width: 150px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
          }
        }

        .right {

          display: flex;
          align-items: center;
          justify-content: space-between;

          .container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-left: auto;

            .undone,
            .link_preview {
              position: relative;
              padding: 0 10px;

              .after_sec {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .icon_sec {
                  margin-right: 10px;
                }

                &::after {
                  content: "" !important;
                  width: 1px !important;
                  background-color: var(--ion-theme-border-color);
                  height: 65% !important;
                  position: absolute !important;
                  right: 0;
                }
              }

            }

            .publish {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 10px;

              app-button {
                ion-button {
                  width: 100%;
                  height: 100%;
                  --background: var(--ion-btn-theme);

                  &::part(native) {
                    border-radius: 3px !important;
                    min-width: 80px;
                    padding: 6px 8px;
                    height: 40px;
                    font-size: 14px;
                    letter-spacing: .5px;
                    margin-right: 10px;
                    text-transform: capitalize;
                    font-weight: 500;
                  }
                }
              }
            }

            .profile {
              margin: 0 0 0 10px;
              background: var(--ion-btn-theme) !important;
              border-radius: 104px;
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 20px;
              position: relative;
              overflow: hidden;
              cursor: pointer;

              ion-avatar {
                width: max-content;
                height: 100%;
                position: absolute;

                img {
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                }
              }

              p {
                font-size: 18px;
                text-transform: uppercase;
                font-weight: 500;
                line-height: 18px;
              }
            }
          }
        }
      }
    }

  }

}

/* header section */

/* inner Section */

app-menu-items {
  .left-side-tab {
    margin-top: 25px;

    .common-menu {
      app-skeleton {
        ion-item {
          --background: var(--ion-select-color);
        }
      }
    }
  }
}

.dual_menu {

  .show_items_preview {
    margin-bottom: 0 !important;

  }

  .center_side {
    .left-side-tab {
      .show_items_preview {
        .preview_items {
          .side_bar_container {
            overflow: scroll;
            height: 720px !important;
          }
        }
      }
    }
  }

  app-menu-items {
    .header_sec {
      .tittle_sec {
        margin-top: 22px;
        margin-bottom: 21px;
      }
    }
  }

  app-menu-dropper {
    .inner_sec {
      .header_sec {
        position: absolute;
        width: -webkit-fill-available;
        background-color: var(--ion-theme-color);
        z-index: 999;

        &::after {
          margin-top: 0;
        }

        .tittle_sec {
          margin: 21.5px 0;
          justify-content: flex-start;
          gap: 50px;

          app-button {
            width: auto;

          }
        }
      }
    }
  }

  .left_side {
    .inner_sec {
      width: 265px;
      border-right: 1px solid var(--ion-theme-border-color);
      overflow-y: scroll;
      height: 100%;
      padding: 0 15px;
      padding-bottom: 50px !important;

      &.left-side-tab {

        &::-webkit-scrollbar {
          display: unset !important;
        }

        &::-webkit-scrollbar {
          padding: 0px;
          width: 3px;
        }

        &::-webkit-scrollbar-thumb {
          background: var(--ion-btn-theme);
          border: 1px solid var(--ion-btn-theme);
        }
      }


      .common-menu {
        &.menu_dropper {
          .custom-placeholder {
            display: flex !important;
          }
        }
      }

      ion-segment {
        flex-direction: row;
        margin-top: 15px;
      }

      .left-side-body {
        section {
          padding-top: 15px;
          display: flex;
          width: 100%;
          justify-content: space-between;
          flex-direction: column;
          align-items: flex-start;
          flex-wrap: nowrap;

          .form_group {
            width: 100%;

            .design-icon {
              display: inline-block;
              vertical-align: middle;
            }

            .icon-content-type {
              width: 30px;
              height: 30px;

              ion-icon {
                width: 100%;
                height: 100%;
              }
            }

            .image-menu {
              .item {
                display: inline-block;
                width: 80px;
                cursor: pointer;
                text-align: center;
                margin-top: 15px;
              }

              .label {
                width: 100%;
                cursor: pointer;
                padding-top: 10px;
                font-size: 11px !important;
                text-align: center;
                font-weight: 400;

                p {
                  margin: 0;
                }
              }
            }
          }

          .form_box {
            width: 100%;
          }

          &.form_box {
            width: 100%;

            p {
              margin: 0;
            }
          }

          &::after {
            content: "";
            height: 1px;
            width: calc(100% + 40px);
            background: var(--ion-theme-border-color);
            margin-top: 15px;
            margin-left: -25px;
          }

          .section-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            width: 100%;

            label {
              margin: 0;
              max-width: 220px;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              font-style: normal;
              font-stretch: normal;
              letter-spacing: normal;
              text-align: left;
              color: var(--ion-white-text);
            }
          }
        }
      }

    }

    .active-menu-items {
      width: 100%;
      height: 100%;
      padding: 15px;
      overflow-y: scroll;
      padding-bottom: 100px;
    }

  }
}


.showcase_page {
  display: flex;
  margin-top: 70px;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: var(--ion-inner-bg);
  position: relative;

  .ai_onboard {
    height: auto;
    width: 100%;
    overflow-y: scroll;

    &::part(background) {
      background: var(--ion-inner-bg);
    }

    .right {
      width: 100%;
      background: transparent;

      app-dropdown {
        .dropdown_component_sec {
          height: 50px;
          border-radius: 10px;
        }
      }

      .back_btn {
        font-size: 28px;
        margin-bottom: 30px;
      }

      .input_filed {
        margin-left: 0;
      }

      ion-button {
        &::part(native) {
          padding: 10px 30px;
          border-radius: 5px;
        }
      }
    }

  }


  &.theme_page {
    align-items: center;
    justify-content: center;
  }

  .left-side-tab {
    .common-menu {
      .drop_box.menu_box {
        position: relative;
        // height: calc(100vh - 130px);
        height: auto;

        .sortable-item {
          &::after {
            content: unset;
          }
        }

        .drag_item_menu {
          position: absolute !important;
          z-index: 999;
          height: 48px !important;
          width: 100% !important;
        }
      }
    }
  }

  .open_component {
    position: absolute;
    // right: -55px;
    left: 120px;
    z-index: 99999;
  }

  .right_side {
    margin-right: -350px;
  }

  .json_from {

    height: 100%;

    .right_side_sec,
    .left_side {
      height: 100%;
    }

  }

  .right_side {
    right: 0;
  }

  .left_side,
  .right_side {
    width: 280px;
    height: 100%;
    background: var(--ion-theme-color);
    position: absolute;
    z-index: 99;
    overflow-y: scroll;
    // overflow-x: unset;

    .header_sec {
      min-height: 38px;
    }

    .form_group {
      .image-menu {
        .item {
          display: inline-block;
          width: 80px;
          cursor: pointer;
          text-align: center;
          margin-top: 15px;
          padding: 6px;
          border: 2px solid transparent;

          &.single_icon {
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--ion-white-text);
          }

          .label {
            width: 100%;
            cursor: pointer;
            padding-top: 10px;
            font-size: 11px !important;
            text-align: center;
            font-weight: 400;
          }
        }
      }

      .icon-content-type {
        width: 40px;
        height: 30px;

        ion-icon {
          width: 100%;
          height: 100%;
          // filter: brightness(0) saturate(100%) invert(59%) sepia(1%) saturate(2153%) hue-rotate(172deg) brightness(97%) contrast(79%);
        }
      }

      .design-icon {
        display: inline-block;
        vertical-align: middle;
        margin: 3px;
        padding: 4px;
      }
    }

    .right-side-body,
    .left-side-body {
      section {
        padding-top: 15px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: nowrap;

        &.title_input {
          &::after {
            content: unset;
          }
        }

        &.title_field {
          padding-top: 0;

          &::after {
            margin-top: 0;
          }
        }

        .date_picker {
          ion-datetime-button {
            &::part(native) {
              border-radius: 3px !important;
              min-width: 80px;
              padding: 6px 8px;
              height: 40px;
              font-size: 14px;
              letter-spacing: 0.5px;
              margin-right: 10px;
              text-transform: capitalize;
              font-weight: 500;
              width: 100%;
              background: var(--ion-btn-theme);
              margin-top: 15px;
              position: relative;
              color: #fff;
            }
          }
        }

        .discount_box {
          .input_sec {
            width: 100%;
          }

          h5 {
            text-align: center;
            font-size: 12px;
            margin: 5px 0 5px;
          }
        }

        .discount_sec {
          line-height: 18px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          height: 38px;
          border: 1px solid #dadada;
          background-color: rgba(255, 255, 255, 0.0196078431);
          color: #ffffff;
          border-radius: 4px;
          padding-right: 15px;
          padding-left: 12px;

          h6 {
            font-size: 12px;
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            width: 190px;
            text-overflow: ellipsis;
          }

          ion-icon {
            font-size: 18px;
          }
        }

        .form_box {
          width: 100%;
        }

        &.form_box {
          width: 100%;

          p {
            margin: 0;
          }
        }

        &::after {
          content: "";
          height: 1px;
          width: calc(100% + 40px);
          background: var(--ion-theme-border-color);
          margin-top: 15px;
          margin-left: -25px;
        }

        .section-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          width: 100%;

          .float-right {
            .title_bar {
              border-radius: 0 !important;

              ion-img {
                height: 100%;
                width: 100%;

                &::part(image) {
                  object-fit: cover;
                }
              }
            }
          }

          .remove_icon {
            position: absolute;
            right: 50px;

            ion-icon {
              font-size: 16px;
              color: #bb2323;
            }
          }

          &.menu_icon_sec {
            .inner_sec {
              .color-picker-container {
                ion-icon {
                  width: 80%;
                  height: 80%;
                }
              }
            }
          }

          .taggle_label_icon {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .icon_span {
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-right: 10px;

              ion-icon {
                width: 100%;
                height: 100%;
              }
            }
          }

          label {
            margin: 0;
            max-width: 220px;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--ion-white-text);
          }
        }
      }
    }
  }


  .left_side {
    height: 100%;
    // overflow-y: scroll;
    display: flex;
    border-right: 1px solid var(--ion-theme-border-color);

    app-showcase-items {
      width: 100%;
    }

    .form_group {
      font-size: 11px;
      font-weight: 500;
    }

    .sub-left-menu-content {
      width: 250px;
      height: 100%;
      padding: 0 !important;

      .header_sec {
        padding: 0 15px;
      }

      .left-side-tab {
        height: 100%;
        margin-top: 44px;

        .common-menu {
          height: 100%;
          width: 96%;
          margin: 0 auto;

          .cdk-drop-list-dragging {
            border: 2.22px dashed var(--ion-btn-theme) !important;
            background: #001633;
          }
        }
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .showcase_items_comp {
      padding: 0 15px;
      padding-bottom: 100px;

      .header_sec {
        padding-bottom: 5px;

        .tittle_sec {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          p {
            font-size: 14px;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            text-align: left;
            color: var(--ion-white-text);
          }

          ion-icon {
            font-size: 24px;
            display: none;
          }
        }
      }

      ion-accordion-group {
        background: transparent;

        ion-accordion {
          background: transparent;

          ion-item {
            &::part(native) {
              padding: 0 !important;
              --padding-start: 0 !important;
              --inner-padding-end: 0 !important;
            }

            &.ion-color-light {
              --ion-color-base: transparent !important;

              ion-label {
                font-size: 14px;
              }
            }
          }

          .ion-padding {
            padding: 15px 0 0;

            .drop_box {
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              width: 100%;
              height: 100%;
              flex-wrap: wrap;

              .showcase_item {
                width: 33.3%;
                margin-bottom: 10px;

                .cdk-drag-placeholder {
                  display: none;
                }

                .card_sec {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  text-align: center;

                  .card_box {
                    height: 60px;
                    width: 75px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 30px;
                    border-radius: 6px;
                    background: var(--ion-select-color);
                    margin: 0 auto;
                    cursor: grabbing;

                    &:hover,
                    &:active,
                    &:visited {

                      ion-icon {
                        color: var(--ion-white-text);
                      }
                    }

                    ion-icon {
                      width: 40px;
                      height: 22px;
                      margin: auto;

                    }

                    &:hover {
                      background: var(--ion-btn-theme);
                    }
                  }

                  .card_text {
                    p {
                      margin: 7px 0 0;
                      line-height: 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  }

  .center_side {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto;

    .colors_view_container {
      margin-left: 10rem;
    }
  }

  .json_form {
    padding: 0 15px;

  }
}

.upload-holder {

  .app-icon-placeholder,
  .app-icon-placeholder2 {
    cursor: pointer;
    background: #fff;
    border-radius: 5px;
    border: 1px dashed #e2e2e2;
    position: relative;
    width: 40px;
    height: 40px;
  }

  .title_bar {
    width: 120px !important;
    height: 30px !important;
  }

  .app-icon-placeholder2 {
    height: 82px;
    border-radius: 0;

    ion-img {
      height: 100% !important;
      width: 100% !important;
      position: relative !important;
      top: 0 !important;
      left: 0 !important;
      margin: 0 !important;
      padding: 0 !important;
    }

    .place-holder {
      height: 11px;
      top: 50%;
      position: absolute;
      left: 50%;
      width: 18px;
      margin-left: -9px;
      margin-top: -6px;
    }
  }

  .description {
    color: var(--ion-white-text);
    font-size: 10px;
    margin: 0;
  }
}

.editor_content {
  height: 100%;
  display: flex;
  flex-direction: column;

  .form_group {
    font-size: 14px;
    font-weight: 500;
    color: var(--ion-white-text);
    padding-bottom: 15px;

    .float-left {
      float: left !important;

      label {
        margin-bottom: 6px;
      }
    }

    .float-right {
      float: right !important;
    }

    .app-icon-placeholder {
      cursor: pointer;
      background: var(--ion-btn-theme);
      border-radius: 5px;
      border: 1px dashed var(--ion-btn-theme);
      position: relative;
      width: 39px;
      height: 39px;

      &::part(image) {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border-radius: 0;
        object-fit: fill;
      }

      img {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border-radius: 0;
        object-fit: fill;
      }
    }

    .description {
      color: var(--ion-text-light-color) !important;
      font-size: 12px;
      margin: 5px 0 0;
    }
  }
}

.form_group {
  position: relative;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  .url_sec {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 15px 0 0;
    cursor: pointer;

    ion-icon {
      font-size: 18px;
    }
  }

  .input_sec {
    margin: 15px 0;

    app-input {
      ion-input {
        min-height: 100%;
        --highlight-color: unset;

        label {
          width: 100%;
          margin-bottom: 0;
        }

        input {
          font-size: 12px;
          height: 38px;
          border: 1px solid #dadada;
          background-color: #ffffff05;
          color: #ffffff;
          border-radius: 4px;
          text-indent: 12px;
          caret-color: unset !important;
        }
      }
    }
  }


  label {
    font-size: 14px;
    font-weight: 500;
    color: var(--ion-white-text);
    vertical-align: middle;
  }

  .image_menu {
    padding: 0;
    width: 210px;
    list-style: none;
    margin: 10px auto;
    flex-wrap: wrap;

    .item {
      color: var(--ion-text-light-color);
      display: inline-block;
      width: 70px;
      cursor: pointer;
      text-align: center;
      padding: 10px 0;

      &:hover {
        background: var(--ion-select-color);
        border-radius: 5px;
      }

      .design-icon {
        display: inline-block;
        vertical-align: middle;
      }

      .label {
        width: 100%;
        cursor: pointer;
        color: var(--ion-text-light-color);
        padding-top: 10px;
        font-size: 12px;
        text-align: center;
        letter-spacing: .5px;
        font-weight: 400;
      }
    }
  }

  .btn_sec {
    app-button {
      ion-button {
        width: 100%;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 500 !important;
        height: 42px;
        --background: var(--ion-btn-theme);
        text-transform: capitalize;
        --box-shadow: unset;
      }
    }
  }
}

.left_closed {
  .left_side {
    // margin-left: -280px;
    margin-left: -120px;
    transition: 0.5s;

    border-right: none;
    background: none;
  }

  .center_side {
    width: 100%;
  }
}

.left_open {
  .left_side {
    margin-left: 0;
    transition: 0.5s;

  }
}

.right_closed {
  .right_side {
    margin-right: -350px;
    transition: 0.5s;
  }
}

.right_opened {
  .right_side {
    margin-right: 0 !important;
    transition: 0.2s;
    padding-bottom: 100px;
  }

}

.image-menu {
  padding: 0;
  width: 245px !important;
  list-style: none;
  margin: 0 auto;
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}


.form_group {
  font-size: 11px;
  font-weight: 500;

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 8px;

    .label-sm {
      font-size: 12px;
      line-height: 18px;
    }
  }



  .form-control {
    font-size: 14px;
    line-height: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 12px;
    height: 44px;
    background: #fff;
    border: 1px solid var(--color-N400);
    border-radius: 4px;
    color: var(--color-N700);
  }
}

.btn_form {

  app-button {
    &.btn_theme {
      ion-button {
        width: 100%;
        height: 100%;
        --background: var(--ion-btn-theme);
        margin-top: 15px;
        position: relative;

        &::part(native) {
          border-radius: 3px !important;
          min-width: 80px;
          padding: 6px 8px;
          height: 40px;
          font-size: 14px;
          letter-spacing: 0.5px;
          margin-right: 10px;
          text-transform: capitalize;
          font-weight: 500;
        }
      }
    }
  }

}

/* inner Section */

/* search filed */

app-search {

  ion-searchbar {
    padding: 0 !important;
    margin: 10px 0;
    --background: var(--ion-inner-bg) !important;
    --box-shadow: unset !important;
    --border-radius: 7px !important;

    input {
      padding-inline-start: 45px;
    }
  }

}

/* search filed */

/* Segment */

ion-segment-button {
  min-width: auto;
  max-width: unset;
  min-height: 36px;
  text-transform: capitalize;
  width: auto;
  flex: 1 1 auto;

  &::part(native) {
    text-align: center;
    padding-left: unset;
    padding-right: unset;
    padding-inline: 0;
  }

  &::part(indicator) {
    --indicator-color: var(--ion-btn-theme);
  }

  ion-label {
    margin: 0 !important;
    font-size: 13px;
    font-weight: 500;
    border: none !important;
    padding: 6px 0;
    text-align: center;
    width: auto;
  }

}

ion-segment {
  flex-direction: column;
  display: flex;
}

/* Segment */


/*menu page*/

.left-side-tab {
  .sortable-item {
    box-shadow: 0 4px 2px 0 rgba(0, 0, 0, .03);
    border: 1px solid #e4e5ec;
    display: flex;
    align-items: center;

    &.title_item {
      border: unset !important;
      box-shadow: unset !important;
      padding: 0;

      p.sortable-text {
        font-size: 15px;
      }

      ion-icon {
        display: none;
      }
    }
  }
}

.empty_item_sec {
  align-items: center;
  background-color: #383a3dab;
  border: 1px solid #e4e5ec;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.03);
  color: var(--ion-text-light-color);
  cursor: auto;
  display: flex;
  font-size: 11px;
  font-weight: 500;
  height: 48px;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding: 10px 8px;
  transition: background-color 0.2s linear;
  width: 100%;

  ion-icon {
    font-size: 15px;
    padding-right: 5px;
  }

  p {
    font-size: 11px;
  }
}

.sortable-item {
  cursor: pointer;
  font-size: 11px;
  color: var(--ion-text-light-color);
  font-weight: 500;
  width: 100%;
  height: 48px;
  padding: 10px 8px;
  margin-bottom: 15px;
  transition: background-color .2s linear;

  .card_sec {
    width: 90%;
  }

  &::after {
    opacity: 0.4;
    content: "";
    display: inline-block;
    background-image: url(assets/svg/tabs_menu/drop_icon.svg);
    background-repeat: no-repeat;
    width: 16px;
    height: 24px;
  }

  .sortable-content {
    display: flex;
    align-items: center;
  }

  .sortable-icon {
    width: 24px;
    height: 20px;
  }

  .sortable-text {
    padding-left: 5px;
  }
}

/*menu page*/


/* Template page */

.template_page {
  height: 100%;
  padding: 10px 30px 30px 75px;
  overflow-y: auto;

  .main_container {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;

      .left {
        width: 100%;

        ion-segment {
          width: 255px;
          flex-direction: row;

          ion-label {
            margin-right: auto !important;
            font-size: 20px;
          }

          ion-segment-button {
            &::part(indicator) {
              width: 45px;
            }
          }
        }
      }

      .right {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .search-box {
          width: 320px;
          padding: 0;
          border: unset;

          a24-search {
            .extra_icon {
              display: none;
            }

            ion-searchbar {
              --background: var(--ion-select-color);
              --box-shadow: unset;
              --border-radius: 4px;
            }
          }

          app-search {
            width: 280px;
            height: auto;

            ion-searchbar {
              width: 100%;
              margin: 0;
              background: var(--ion-select-color);
              border-radius: 5px;
            }
          }
        }

        app-dropdown {
          .dropdown_comp {
            ion-list {
              border: unset;
              padding: 0;
              background-color: transparent;

              ion-item {
                ion-select {
                  height: 100%;
                }
              }
            }
          }
        }

        .select_button {
          display: flex;
          align-items: center;
          justify-content: space-between;

          ion-buttons {
            background: var(--ion-select-color);
            height: 40px;
            border-radius: 5px;

            ion-button {
              text-transform: capitalize;
              font-size: 12px;
              position: relative;
              cursor: pointer;
              color: var(--ion-text-light-color);

              &::after {
                content: "";
                content: "";
                background: var(--ion-white-text);
                height: 56%;
                width: 1px;
                position: absolute;
                top: 23%;
                right: -4px;
              }

              &.without_line {
                &::after {
                  content: unset;
                }
              }
            }
          }
        }
      }
    }
  }
}

.body {

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 100%;
  margin-top: 20px;

  .body_content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 15px;
    }
  }



  ion-card {
    margin: 0;
    height: 380px;
    width: 16%;
    background: var(--ion-select-color);
    margin-right: 20px;
    min-width: 315px;
    cursor: pointer;
    margin-bottom: 20px;
    padding: 60px 0 0;
    border-radius: 10px;

    &.selected_card {
      border: 2px dashed var(--ion-btn-theme);
    }

    ion-card-header {
      padding: 0;
    }

    ion-card-content {
      padding: 10px;
      height: 100%;
    }

    .card_title {
      position: absolute;
      top: -48px;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
    }

    .right {
      padding-right: 10px;
    }
  }
}

.like_btn {
  position: absolute;
  top: 20px;
  right: 20px;

  ion-icon {
    color: var(--ion-btn-theme);
    font-size: 40px;
  }
}

.card_txt {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ion-icon {
    padding-left: 10px;
    font-size: 16px;
  }

  p {

    margin: 0;
    font-size: 16px;

  }
}

.tag {
  background: var(--ion-btn-theme);
  height: 35px;
  line-height: 26px;
  padding: 0 10px;
  margin: 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  border-radius: 0 20px 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;

  p {
    margin: 0;
    padding-right: 10px;
  }
}

.color_btn {
  display: flex;
  align-items: center;
  justify-self: flex-end;
}

.color-round {
  .color_picked {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-left: 3px !important;
    margin: 0;
  }
}

.drop_button {
  ion-select {
    width: 100px;
    padding: 10px;
    background: var(--ion-select-color);
    border-radius: 5px;
    font-size: 14px;
    color: var(--ion-text-light-color);
  }
}

/* Template page */

/* all modal page design */

ion-modal {
  --width: 25%;
  --border-radius: 10px;
  --box-shadow: unset;
  --height: auto;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.7);

  .web_login {
    .right {
      width: 100%;

      .login_pages {
        width: 100%;
      }
    }
  }

  app-app-build-device-modal {
    .modal-body {
      padding: 15px 0 30px;

      .qr-code {
        display: flex;
        align-items: flex-start;
        gap: 30px;
        margin-top: 0;
        padding: 15px;
        background: var(--ion-inner-bg);
        border-radius: 10px;

        ion-icon {
          font-size: 50px;
          height: 25px;
        }

        &.pending_task {
          margin-top: 15px !important;
        }

        h3 {
          margin: 0;
          font-size: 15px;
        }
      }
    }

    .modal-content {
      .info {
        margin: 50px 0 0;

        .icons {
          width: auto;
          gap: 25px;
          margin-bottom: 30px;

          .icon_mobile {
            padding: 10px;
          }
        }
      }
    }
  }

  .app_publish_form {

    .double_input_drop {
      width: 100%;

      .dropdown_component_sec {
        height: 50px;
        width: 100%;

        .ng-placeholder {
          font-size: 14px !important;
        }
      }
    }

    .right {
      width: 100%;
      padding: 20px 0;

      .login_pages {
        min-width: 440px;

        .field {
          margin: 20px 0 15px !important;
        }

        .header_boxs {
          h2 {
            font-size: 23px;
          }
        }
      }

      .input_box {
        .input_filed {
          margin-left: 0 !important;
        }
      }

      .txt_area {
        height: auto !important;
        position: relative;

        .err-length-count {
          position: absolute;
          top: -36px;
          right: 10px;
          background: #1e56e7;
          border-radius: 6px;
          padding: 5px 10px;
          color: #fff;
          font-size: 12px;
        }

        .input_filed {
          width: 100% !important;

          .textarea-legacy-wrapper {
            height: 150px !important;
          }

          textarea {
            color: var(--ion-white-text);
            height: 150px;
            overflow-y: scroll;
          }
        }
      }
    }
  }

  app-theme-preview {
    .intro_sec {
      .intro_container {
        background: transparent !important;
        align-items: flex-start !important;
      }

      .intro_left {
        background: #f1f3f4;
      }

      .intro_right {
        padding: 0 30px !important;

        .desc_sec {
          height: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 100%;
          justify-content: space-between;
          height: 500px !important;
        }
      }

      .btns {
        margin-bottom: 20px;
        width: 100%;
      }
    }

    .detail_desc {
      font-size: 13px;
      margin: 15px;
      display: block;
      line-height: 18px;
    }

    .header_sec_box {
      display: flex;
      align-items: center;
      padding: 10px 20px;

      ion-icon {
        font-size: 30px;
      }

      h3 {
        margin: 10px 0;
        font-size: 18px;
      }
    }
  }

  &.collection_modal {
    // overwrite inline styles
    --offset-bottom: auto !important;
    --overflow: hidden;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.time_zone_modal {
    &::part(content) {
      height: 85%;
    }
  }

  &.counter_datepick {
    &::part(content) {
      max-width: max-content !important;
    }

    ion-buttons {
      ion-button {
        margin: 15px;
        background: var(--ion-btn-theme);
        width: 100%;

        &::part(native) {
          color: var(--ion-white-text);
        }
      }
    }

    ion-datetime {
      --background: var(--ion-theme-color) !important;
      color: var(--ion-white-text) !important;
      --wheel-fade-background-rgb: var(--ion-theme-color);
      --wheel-highlight-background: var(--ion-theme-color);

      .picker-after {
        background: unset !important;
      }

      .calendar-action-buttons {
        ion-item {
          color: #ffffff !important;
        }
      }

    }
  }

  &.menu_icon {
    &::part(content) {
      max-width: 550px !important;
    }

    app-icons {
      .modal-content {
        .modal-body {
          padding: 15px 25px 25px;
          height: 90vh;
          overflow: scroll;
        }
      }
    }
  }

  app-collection-select,
  app-auto-upload {
    .modal-content {
      .modal-body {


        ion-content {
          --background: transparent;
        }

        .left-side-content {
          width: auto;
        }

        .right-side-content {
          width: 100%;

          .img-holder {
            width: 84px;
            height: 84px;
            flex: none;
            order: 0;
            flex-grow: 0;

            &.placeholder {
              position: relative;
              background: #f9f9f9;
              border-radius: 2px;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                height: 100%;
              }
            }
          }


          .item_container {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
          }

          .item {
            width: 285px;
            cursor: pointer;
            background: var(--ion-theme-color);
            padding: 12px;
            display: flex;
            margin-right: 8px;
            margin-bottom: 8px;
            position: relative;
            // border: 1px solid #fff;
            border-radius: 4px;
            transition: all 0.2s linear;
            border: 1px solid transparent;

            &:hover {
              border: 1px solid var(--ion-btn-theme);
            }

            .text-holder {
              padding: 5px 5px 0 10px;
              font-size: 12px;

              .product_text {
                color: var(--text-color);
              }

              .buttons {
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                position: absolute;
                bottom: 0;
                right: 0;

                li {
                  &:not(:last-child) {
                    margin-right: 4px;
                  }

                  .icon-deeplink-upload {
                    width: 20px;
                    height: 20px;
                    margin: 10px;

                    ion-icon {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  app-choose-product-type {
    .modal-content {
      .modal-header {
        .content-holder {
          padding: 15px 35px 0 !important;
        }
      }

      .modal-body {
        border: unset;

        .continue_btn {
          app-button {
            ion-button {
              width: 100px;
              float: right;
              margin: 20px 0;
            }
          }
        }

        ion-radio {
          &::part(container) {
            border-color: var(--ion-white-text);
          }
        }

        ion-radio-group {
          .radio_group {
            margin-bottom: 15px;

            .radio_des {
              .alt-desc {
                white-space: pre-wrap;
                font-size: 14px;
                margin-top: 4px;
              }
            }

            .shopney-radio-inline {
              margin-top: 3px;

              ion-radio {
                &::part(container) {
                  border-color: var(--ion-white-text);
                }

                .radio_des {
                  margin-left: 20px;
                }
              }
            }
          }
        }
      }
    }

    ion-button {
      width: 100%;
      height: 100%;
      --background: var(--ion-btn-theme);

      &::part(native) {
        border-radius: 3px !important;
        min-width: 80px;
        padding: 6px 8px;
        height: 40px;
        font-size: 14px;
        letter-spacing: 0.5px;
        margin-right: 10px;
        text-transform: capitalize;
        font-weight: 500;
      }
    }
  }

  &.icon_modal_container {
    &::part(content) {
      max-width: 1200px;
      min-height: 700px;
    }
  }

  app-icons {
    .modal-content {
      .modal-body {
        border: unset;
      }
    }
  }

  .icons_container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    // height: 800px;
    flex-wrap: wrap;
    gap: 10px;


    .icon {
      width: 75px;
      height: 75px;
      // margin: 10px;
      padding: 13px;
      background-color: var(--ion-select-color);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.selected {
        background: var(--ion-btn-theme);
        border: unset !important;

        ion-icon {
          color: var(--ion-white-text);
        }
      }

      ion-icon {
        font-size: 24px;
      }
    }
  }

  &.color_picker_modal {
    app-color-picker {

      .footer_color {
        position: fixed;
        bottom: 15px;
        width: 100%;
        background: var(--ion-theme-color);
        padding-top: 10px;

        .form_group {

          .error {
            padding: 8px 40px 0;
            color: var(--ion-failed-color) !important;
          }

          label {
            padding: 0px 40px;
          }

          .input_sec {
            display: flex;
            align-items: center;
            margin: 5px 0 0;
            justify-content: space-between;
            padding: 0 40px;

            ion-input {

              label {
                padding: 0 !important;
              }
            }

            .color_code {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              .color_box {
                width: 38px;
                height: 38px;
                background: red;
                border: 1px solid var(--ion-theme-border-color);
                margin-right: 15px;
              }
            }

            app-input {
              width: 75%;

              .input_sec {
                padding: 0 !important;
                margin: 0;
                margin-right: 10px;
              }
            }

            app-button {
              width: 100px !important;

              ion-button {
                --background: var(--ion-btn-theme);
              }
            }
          }
        }
      }

      height: 600px;
      width: 100%;
      margin: auto;

      .color_fields {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 2px;
        overflow-y: scroll;
        padding: 20px 10px;
        margin-bottom: 100px;
      }

      .color {
        display: block;
        width: 45px;
        height: 45px;
        border: unset;
        margin: 0;
        float: left;
        overflow: hidden;
        cursor: pointer;
      }
    }
  }

  // &::part(backdrop) {
  //   background-color: rgb(183, 183, 183);
  // }

  &#video-upload-modal {
    &::part(content) {
      max-width: 60%;
      height: calc(var(--extra-height) - 9.5rem);
    }

    .screen_shot {
      margin-top: 20px;
    }

    .screen_container {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 130px;
      border: 1px dashed var(--ion-theme-border-color);
      text-align: center;
      padding: 0 45px;
      background: var(--ion-theme-color);
      gap: 10px;

      .screen_txt {
        width: 65%;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 23px;
        margin: 0 auto;
      }
    }

    .screen_container {
      .screen_img {
        object-fit: cover;
        width: 110px;
        height: 110px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .video_frame {
      width: 70%;
      margin: 25px auto;
      height: 350px;

      audio,
      canvas,
      progress,
      video {
        width: 100%;
        height: 100%;
      }
    }

    .btn_form {
      width: fit-content;
      margin: 0 auto;
      display: flex;
      align-items: center;

      ion-button {
        margin-top: 0;
      }

      p {
        margin: 0 15px;
      }
    }
  }

  .video_upload_section {
    padding: 40px;

    .footer_btn_sec {
      width: 250px;
      margin: 0 auto;
      text-align: center;

      .btn_form {
        margin-bottom: 15px !important;
      }
    }

    .or_txt {
      margin: 12px !important;
      text-align: center;
      font-size: 14px;
    }

    .form_group {
      height: auto;

      .input_sec {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0 0;

        app-input {
          width: 84%;

          .input_sec {
            margin: 0;
          }
        }

        app-button {
          width: 10%;

          &.btn_theme {
            ion-button {
              margin-top: 0 !important;
            }
          }
        }
      }
    }
  }

  &.collection_modal,
  &.app-collection-select {
    &::part(content) {
      height: calc(var(--extra-height) - 3.5rem);
      max-width: 90%;
    }

    .modal-content {
      height: 100%;
    }

    .content-holder {
      width: 25%;
    }

    .button-holder {
      width: 75%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      app-dropdown {
        width: 250px;
      }

      .btns {
        app-button {
          margin-left: 10px;
        }

        .cancel {
          ion-button {
            border: 1px solid var(--ion-btn-theme);
            border-radius: 4px;
            --background: transparent;
          }
        }
      }

      a24-search {
        width: 280px;
        height: auto;

        ion-searchbar {
          --background: var(--ion-inner-bg);
          --box-shadow: unset;
        }

        .search_box {
          .extra_icon {
            display: none;
          }
        }

      }

      .btns {
        display: flex;
        align-items: center;
      }
    }

    .modal-body {
      width: 100%;
      height: 92%;
      padding: 0 20px 20px;
      display: flex;
      background: var(--ion-inner-bg) !important;

      &.video_upload {
        flex-direction: column;
      }

      .steps-widget {
        background: transparent;
        height: 80px;
        border-bottom: 1px solid var(--ion-theme-border-color);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .step {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100px;
          z-index: 2;

          &.active {
            .icon {
              background-color: var(--ion-success-color);
            }
          }

          .icon {
            width: 32px;
            height: 32px;
            background: var(--ion-theme-color);
            color: var(--ion-white-text);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 16px;
            font-weight: 500;
          }

          .texts {
            font-size: 11px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;
            text-align: center;
            margin-top: 8px;
            color: var(--ion-white-text);
            white-space: nowrap;

          }
        }

        .divider {
          height: 2px;
          margin-right: -35px;
          background: var(--ion-theme-color);
          align-self: normal;
          margin-top: 26px;
          margin-left: -35px;
          width: 100px;
          z-index: 1;
        }
      }

      .left-side-content {
        .search-result-holder {
          width: 98% !important;
        }
      }

      .left-side-content,
      .right-side-content {
        width: 50%;
        position: relative;

        .side_action_bar {
          padding-right: 10px;
          width: 250px !important;
          border-right: 1px solid var(--ion-theme-border-color);
          height: 100%;
          padding-top: 20px;
          margin-right: 10px;
          padding-right: 20px;

          p {
            margin-bottom: 10px;
          }

          app-dropdown {
            .dropdown_comp {
              ion-list {
                margin-bottom: 15px;
              }
            }
          }
        }

        cdk-virtual-scroll-viewport {
          .cdk-virtual-scroll-content-wrapper {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 50px;
            height: auto;
            align-items: flex-start;
            justify-content: flex-start;
          }
        }

        .content-head {
          .centered-content-title {
            h5 {
              font-size: 16px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    .search-result-section {
      overflow: scroll;

      &::part(scroll) {

        // overflow: unset;
        &::-webkit-scrollbar {
          display: block !important;
        }

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background: var(--ion-btn-theme);
          border: 1px solid var(--ion-btn-theme);
        }
      }

      &::part(background) {
        background: transparent !important;
      }

      &::-webkit-scrollbar {
        display: block !important;
      }

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--ion-btn-theme);
        border: 1px solid var(--ion-btn-theme);
      }

    }

    .search-result-holder {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      overflow-y: auto;
      overflow-x: hidden;
      flex-wrap: wrap;
      align-content: flex-start;
      // height: 100%;
      gap: 6px;
      padding-bottom: 45px;

      &::-webkit-scrollbar {
        display: block !important;
      }

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--ion-btn-theme);
        border: 1px solid var(--ion-btn-theme);
      }

      &.sel_items {
        gap: 6px !important;
      }

      .img-holder {
        width: 84px;
        height: 84px;
        flex: none;
        order: 0;
        flex-grow: 0;

        &.placeholder {
          position: relative;
          background: #f9f9f9;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .item {
        width: 285px;
        cursor: pointer;
        background: var(--ion-select-color);
        padding: 12px;
        display: flex;
        margin-right: 8px;
        margin-bottom: 8px;
        position: relative;
        // border: 1px solid #fff;
        border-radius: 4px;
        transition: all 0.2s linear;
        border: 1px solid transparent;
        height: 115px;

        &:hover {
          border: 1px solid var(--ion-btn-theme);
        }

        .text-holder {
          padding: 5px 5px 0 10px;
          font-size: 12px;

          h4 {
            margin: 0;
            font-size: 18px;
            word-break: break-all;
          }

          .product_text {
            color: var(--text-color);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 32px;
            margin-bottom: 10px;
            word-break: break-word;
          }

          .buttons {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: absolute;
            bottom: 0;
            right: 0;

            li {
              &:not(:last-child) {
                margin-right: 4px;
              }

              .icon-deeplink-upload {
                width: 25px;
                height: 25px;
                margin: 10px;
                background: var(--ion-btn-theme);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 2px;

                ion-icon {
                  width: 15px;
                  height: 15px;
                }
              }
            }
          }
        }
      }
    }

    .modal-header {

      padding-left: 20px;
      padding-right: 20px;
      align-items: center;
      height: 70px;
      min-height: 70px;
      background: transparent;
      z-index: 2;
      display: flex;
      position: relative;

      .content-holder {
        display: flex;
        align-items: center;

        .header-text {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          text-align: left;
        }
      }
    }

  }

  &.qr_modal {
    &::part(content) {
      max-width: 330px;
    }

    .modal-content {
      .modal-body {
        padding: 60px 32px 32px;
      }
    }
  }

  &::part(content) {
    max-width: 520px;
    width: 100%;
    min-height: auto;
    box-shadow:
      /* offset-x | offset-y | blur-radius | spread-radius | color */
      0px 24px 38px 3px hsla(0, 0%, 0%, 0.14),
      0px 9px 46px 8px hsla(0, 0%, 0%, 0.12),
      0px 11px 15px -7px hsla(0, 0%, 0%, 0.2);
    background: var(--ion-theme-color);
  }

  &.crop-modal {
    --height: 60%;



    .image_crop_model {
      .crop_head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .left {
          display: flex;
          align-items: center;
          width: 80%;
          justify-content: flex-start;

          .icon_sec {
            margin: 10px;
            cursor: unset;
          }

          .header_txt {
            margin-left: 20px;

            h2,
            p {
              margin: 8px 0;
            }
          }
        }

        .right {
          .cancel_modal {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 20px;
          }
        }
      }

      &.modal-content {
        height: 100%;
      }

      .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 15px 15px 20px;
        height: 100%;
      }



      // image-cropper {
      //   height: 370px;
      //   align-items: center;
      //   justify-content: center;

      //   .ngx-ic-source-image.ngx-ic-draggable {
      //     width: 100%;
      //     height: 314px;
      //     object-fit: contain;
      //     padding: 15px;
      //   }

      //   .ngx-ic-overlay {
      //     height: 100% !important;

      //   }

      //   // .ngx-ic-cropper {
      //   //   height: 100% !important;
      //   // }
      // }

      .btn_sec {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 60%;
        margin-left: auto;
        margin-right: -15px;

        ion-button {
          height: 44px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          border-radius: 4px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          min-width: 80px;
          margin: 0;
          --box-shadow: unset;
          width: 90%;
        }

        app-button {
          &.cancel {
            ion-button {
              border: 1px solid var(--ion-btn-theme);
              color: var(--ion-white-text);
              --background: transparent;
            }

          }

          &.save {
            ion-button {
              --background: var(--ion-btn-theme);
            }
          }
        }
      }
    }
  }

  .providers {
    .title {
      margin-top: 0;
    }

    .drop_down {
      width: 80%;
      margin-top: 20px;

      ion-list {
        ion-item {
          ion-select {
            &::part(icon) {
              right: 15px;
            }
          }
        }
      }
    }
  }

  .description,
  .title {
    font-style: normal;
    line-height: 20px;
    text-align: center;
  }


  .modal-content {
    border-radius: 5px !important;
    border: none !important;
    background: unset;

    .qr-code {
      margin: 0 auto;
      width: 90%;

      img {
        width: 100%;
        height: 100%;
      }

    }

    .info {
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 30px 0 0;

      .icons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90px;
        margin-bottom: 15px;
      }

      .icon-ios,
      .icon-android {
        width: 32px;
        height: 28px;

        ion-icon {
          width: 100%;
          height: 100%;
        }
      }

      .icon-android {
        ion-icon {
          color: #64b462;
        }
      }

      .text-center {
        text-align: center;
        width: auto;
        line-height: 23px;
        font-size: 14px;
      }
    }

    .modal-body {
      background: var(--ion-theme-color);
      border: 1px solid var(--ion-theme-border-color);
      border-radius: 10px;

      .left_containers {
        width: 100%;
      }

      .icon_sec {
        width: 55px;
        height: 55px;

        ion-icon {
          width: 100%;
          height: 100%;
        }
      }

      h2,
      p {
        margin: 0;
      }

      .description {
        p {
          font-size: 16px;
        }
      }

      .buttons {
        margin-top: 32px;

        ion-button {
          min-width: 80px;
          margin: 0;
          --box-shadow: unset;

          &::part(native) {
            background: transparent;
            width: 100%;
            margin: 0;
            padding: 0 18px;
          }
        }
      }
    }
  }

  &.intro-slides-modal {
    --border-radius: 4px;

    swiper-slide {
      height: auto;
    }

    &::part(content) {
      max-width: 1000px;
    }

    .intro_sec {
      .intro_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        background: var(--ion-inner-bg);

        .intro_left,
        .intro_right {
          width: 50%;
          height: 100%;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        .intro_right {
          padding: 30px;
          position: relative;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-between;

          .desc_sec {
            h3 {
              text-align: center;
              margin: 10px 0 25px;
            }

            .desc_items {

              &.inner-list:nth-child(4),
              &.inner-list:nth-child(5),
              &.inner-list:nth-child(6) {
                padding-left: 35px;
              }
            }

            .desc_items {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-bottom: 10px;

              ion-icon {
                width: 35px;
                height: 25px;
                margin-right: 10px;
                color: var(--ion-btn-theme);
              }

              p {
                margin: 0;
                font-size: 14px;
                line-height: 20px;
                width: 90%;
              }
            }

            .skipBtnCon {
              width: 100%;
              height: 25px;

              .skip {
                cursor: pointer;
                font-size: 14px;
                display: inline;
                float: inline-end;
                margin: 0px;
                padding-right: 10px;
              }
            }
          }

          .tips_sec {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: 97%;
            padding: 10px 20px;
            border: 1px solid var(--ion-btn-theme);
            border-radius: 4px;
            margin: 20px 10px;

            p {
              margin: 0;
              font-size: 12px;
              line-height: 20px;
            }

            .tips_left {
              ion-icon {
                font-size: 24px;
                padding-right: 12px;
              }
            }
          }

          .actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-top: 40px;

            ion-button {
              --background: var(--ion-btn-theme);
            }

            .pagination {
              display: flex;
              align-items: center;
              justify-content: space-between;
              min-width: 100px;

              p {
                font-size: 18px;
                margin: 0 10px;
              }

              ion-button {
                --background: transparent;
                height: 37px;
                background: var(--ion-btn-theme);
                font-size: 14px;
                text-transform: capitalize;
              }

              ion-icon {
                width: 15px;
                height: 14px;
                background: var(--ion-btn-theme);
                padding: 7px;
                border-radius: 3px;

                &.disable {
                  background-color: #ccc !important;
                  color: #666;
                  cursor: auto;
                }
              }

            }

            .skip {
              cursor: pointer;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  app-theme-preview {
    .intro_sec {
      .intro_container {
        position: relative;

        .button-holder {
          position: absolute;
          right: 15px;
          top: 10px;
          z-index: 9999;

          ion-icon {
            font-size: 30px;
          }
        }

        .intro_right {
          .desc_sec {
            h3 {
              margin: 20px 0 20px !important;
            }
          }

          .desc_items {
            margin-bottom: 2px !important;

            ion-icon {
              width: 25px;
              height: 25px;
            }

            p {
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }

}

ion-alert {
  .alert-button-group {
    button {
      &.alert-button {
        width: auto;
        height: 45px;
        margin: 0 !important;
        text-transform: capitalize;
        font-size: 14px;
        background: var(--ion-btn-theme);
        border-radius: 9px;
        padding: 0 25px;
      }
    }
  }
}

.icon-popup-close {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;

  ion-icon {
    width: 100%;
    height: 100%;
  }
}

.btn {
  &.btn-shopney {
    height: 44px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    /* add color for btn here */

    &.btn-shopney-theme {
      background-color: var(--ion-btn-theme);

      &.btn-shopney-outline {
        border: 1px solid var(--ion-btn-theme);
        color: var(--ion-btn-theme);
        background: transparent;
      }
    }

    &.btn-shopney-warning {
      background-color: var(--ion-warning-color);

      &.btn-shopney-outline {
        border: 1px solid var(--ion-warning-color);
        // color: var(--ion-warning-color);
        background: transparent;
      }
    }

    &.btn-shopney-success {
      background-color: var(--ion-success-color);

      &.btn-shopney-outline {
        border: 1px solid var(--ion-success-color);
        color: var(--ion-white-text);
        background: transparent;
      }
    }

    &.delete-btn-shopney-warning {
      background-color: var(--ion-failed-color);

      &.delete-btn-shopney-outline {
        border: 1px solid var(--ion-failed-color);
        // color: var(--ion-white-text);
        background: transparent;
      }
    }

    /* add color for btn here */
  }
}

.description {

  font-weight: 400;
  font-size: 14px;
  color: var(--color-N700);
  margin-top: 8px;
}

.title {
  font-weight: 600;
  font-size: 16px;
  margin-top: 32px;
}

.modal-body {
  padding: 32px;
  background: transparent;
}

ion-popover {
  ion-select-popover {
    ion-list {
      padding: 0 !important;

      ion-radio-group {
        ion-item {
          .ion-radio {
            --color: var(--ion-white-text) !important;
            color: var(--ion-white-text) !important;
          }

          ion-radio {
            --color: var(--ion-white-text) !important;
            color: var(--ion-white-text) !important;
          }

          ion-label {
            --color: var(--ion-white-text) !important;
          }

          &.item-radio-checked {
            --background: var(--ion-btn-theme) !important;
          }

          --background: #1e1e1e !important;
        }
      }
    }
  }
}

/* all modal page design */

/* login page design */

.web_login,
.profile_page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .content_box {
    display: flex;
    align-items: center;
    gap: 50px;

    .logo_box {
      ion-icon {
        width: 70px;
        height: 70px;
        margin-left: 30px;
      }
    }
  }

  .logo_icon {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 50px;

    ion-icon {
      width: 50px;
      height: 50px;
    }

    span {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .left {
    width: 65%;
    background-color: var(--ion-mobile-bg);
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .top_vector {
      position: absolute;
      top: -20px;
      left: -70px;

      ion-icon {
        width: 400px;
        height: 35vh;
        cursor: unset;
      }
    }

    .bottom_vector {
      position: absolute;
      bottom: -50px;
      right: -7px;

      ion-icon {
        width: 400px;
        height: 35vh;
        cursor: unset;
      }
    }

    .center_content_container {
      height: auto;
      position: absolute;
      z-index: 9999;
      width: 100%;
      max-width: 450px;
      top: 8%;

      p {
        color: var(--ion-btn-theme) !important;
        font-size: 20px;
        font-weight: 600;
      }

      h2 {
        color: var(--ion-text-black);
        font-size: 2.5rem;
        width: 100%;
        line-height: 3.1rem;
        font-weight: 900;
      }

      .video_sec {
        width: 100%;
        height: 500px;
        display: block;
        padding-top: 25px;


        ion-icon {
          width: 100%;
          height: 100%;
          cursor: unset;
        }
      }
    }
  }

  .right {
    width: 35%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--ion-theme-color);

    .login_pages {
      width: auto;
      margin: 0 30px;
      min-width: 360px;

      .header_boxs {
        h2 {
          font-size: 34px;
          font-weight: 700;
        }

      }

      ion-card {
        background: transparent;
        margin: 0;
        box-shadow: unset;

        ion-card-content {
          background: transparent;
          padding: 0;

          ion-list {
            background: transparent;
            padding: 0;

            .field {

              margin: 20px 0 0;

            }

            ion-item {
              --background: transparent;
              --padding-start: 0;
              padding-inline-end: 0px !important;
              --inner-padding-end: 0;

              app-label {
                ion-label {
                  transform: unset;
                  margin-bottom: 10px;
                }
              }

              .input_box {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 50px;
                background: transparent;
                padding: 0 20px;
                border-radius: 10px;
                width: 100%;
                border: 1px solid #E0E0E0;
                position: relative;

                .password_icon {
                  position: absolute;
                  top: 15px;
                  right: 15px;
                }



                .icon_box {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  ion-icon {
                    font-size: 18px;
                  }
                }

                .input_filed {
                  margin-left: 18px;
                  width: 83%;
                }
              }
            }
          }

          .forget_sec {
            margin: 15px 0 5px;

            a {
              cursor: pointer;
            }
          }

          .submit_sec {
            margin: 20px 0;

            ion-button {
              width: 100%;
              height: 50px;
              margin: 0 !important;
              --box-shadow: unset;
              text-transform: capitalize;
              font-size: 14px;
              --background: var(--ion-btn-theme);
              --border-radius: 10px;
            }
          }


        }
      }

      .continue-with {
        p {
          width: 100%;
          text-align: center;
          border-bottom: 1px solid #E0E0E0;
          line-height: 0.1em;
          margin: 10px 0 20px;

          span {
            background-color: var(--ion-theme-color);
            padding: 10px 20px;
          }
        }
      }

      .social_login {
        margin-top: 30px;

        ion-button {
          width: 100%;
          height: 50px;
          margin: 0 !important;
          --box-shadow: unset;
          text-transform: capitalize;
          font-size: 14px;
          --background: transparent;
          --border-radius: 10px;
          border: 1px solid #E0E0E0;
          border-radius: 10px;

          ion-icon {
            font-size: 28px;
            padding-right: 15px;
          }
        }
      }
    }
  }
}

/* login page design */

app-settings-editor {
  width: 100%;
}



/*all animation style here*/


// app item add animation

.block {
  z-index: 100;
  -webkit-animation: scale 0.1s linear;
  -moz-animation: scale 0.1s linear;
  animation: scale 0.3s linear;
  transform-origin: 50% 50%;
  transition-property: margin-bottom;
  transition-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes scale {
  0% {
    opacity: 0;
    -webkit-transform: scale(.8);
    transform: scale(.8)
  }

  50% {
    opacity: 0.8;
    -webkit-transform: scale(0.7);
    transform: scale(0.7)
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);

    -webkit-box-shadow: 10px 10px 60px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 10px 10px 60px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 10px 10px 60px 10px rgba(0, 0, 0, 0.1);
  }
}

@keyframes scaledown {
  10% {

    opacity: 0;
    -webkit-transform: scale(.8);
    transform: scale(.8);
  }

  50% {
    opacity: 0.5;
    -webkit-transform: scale(0.5);
    transform: scale(0.5)
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

// app item add animation

#arrowAnim {
  width: 55px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  background: var(--ion-btn-theme);
  border-radius: 0 50% 50% 0;
  margin-left: 0;
}

.arrow {
  width: 100%;
  height: 100%;
  border: 6px solid;
  border-color: #ffffff transparent transparent #ffffff;
  transform: rotate(135deg);
  margin-left: -18px;
}


.arrowSliding {
  position: absolute;
  -webkit-animation: slide 4s linear infinite;
  animation: slide 4s linear infinite;
}

.delay1 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.delay2 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.delay3 {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

@-webkit-keyframes slide {


  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  20% {
    opacity: 1;
    transform: translateX(-13px);
  }

  80% {
    opacity: 1;
    transform: translateX(13px);
  }

  100% {
    opacity: 0;
    transform: translateX(20px);
  }

}

@keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  20% {
    opacity: 1;
    transform: translateX(-13px);
  }

  80% {
    opacity: 1;
    transform: translateX(13px);
  }

  100% {
    opacity: 0;
    transform: translateX(20px);
  }


}

/*all animation style here*/

.card_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  .card_sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;
  }

  :hover {
    // border: 1px solid var(--ion-btn-theme);
    background: var(--ion-btn-theme);
  }

  .selected {
    // border: 1px solid var(--ion-btn-theme);
    background: var(--ion-btn-theme);
  }
}

app-menu {
  .menu_page {
    display: flex;
    margin-top: 70px;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background: var(--ion-inner-bg);
    position: relative;

    &.dual_menu {
      .left_side {
        width: auto;
      }

      .left-side-tab {
        .menu_dropper {
          margin-top: 75px;
          // height: calc(100vh - 130px);
          // min-height: 100%;

          .drop_box.menu_box {
            height: 100%;
            min-height: calc(100vh - 270px);
          }

          .sortable-item {
            &::after {
              content: "" !important;
            }
          }
        }
      }
    }

    .center_side {
      .left-side-tab {
        width: 300px;
        position: relative;
        margin: 0 auto;
        z-index: 9999;

        .show_items_preview {
          .preview_items {
            position: relative;
            left: 8rem;

            .side_bar_container {
              margin-top: -56px;
              z-index: 99999;
              position: relative;
              width: 82%;
              min-height: 720px;
              height: 100%;
            }
          }
        }

        &.drop_item_tab {
          width: 100%;
          z-index: 9;

          .common-menu {
            height: 100%;

            .menu_box {
              height: 100%;
            }
          }
        }
      }
    }

    .left-side-tab {
      .common-menu {
        .custom-placeholder {
          display: none !important;
        }
      }
    }

    .open_component {
      position: absolute;
      right: -55px;
      z-index: 99999;
    }

    .right_side {
      margin-right: -350px;
    }

    .json_from {

      height: 100%;

      .right_side_sec,
      .left_side {
        height: 100%;
      }

    }

    .right_side {
      right: 0;
    }

    .left_side .main_menu,
    .right_side {
      // width: 280px;
      height: 100%;
      background: var(--ion-theme-color);
      position: absolute;
      z-index: 99;
      border-left: 1px solid var(--ion-theme-border-color);
    }

    .right_side_sec {
      .tittle_sec {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        p {
          font-size: 14px;
          margin: 20px 0px 10px;
        }

        ion-icon {
          font-size: 24px;
        }
      }
    }

    .left_side .main_menu {
      height: 100%;
      // overflow-y: scroll;
      display: flex;
      border-right: 1px solid var(--ion-theme-border-color);

      .sub-left-menu-content {
        width: 250px;
        height: 100%;
        padding: 0 !important;

        .header_sec {
          padding: 0 15px;
        }

        .left-side-tab {
          height: 100%;
          margin-top: 44px;

          .common-menu {
            height: 100%;
            width: 96%;
            margin: 0 auto;

            .cdk-drop-list-dragging {
              border: 2.22px dashed var(--ion-btn-theme) !important;
              background: #001633;
            }
          }
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .main_menu {
        padding: 0 15px;

        .header_sec {
          width: 100%;
          padding-bottom: 10px;

          .tittle_sec {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            p {
              font-size: 14px;
              margin: 20px 0px 10px;
            }

            ion-icon {
              font-size: 24px;
            }
          }
        }

        ion-accordion-group {
          background: transparent;

          ion-accordion {
            background: transparent;

            ion-item {
              &::part(native) {
                padding: 0 !important;
                --padding-start: 0 !important;
                --inner-padding-end: 0 !important;
              }

              &.ion-color-light {
                --ion-color-base: transparent !important;

                ion-label {
                  font-size: 14px;
                }
              }
            }

            .ion-padding {
              padding: 15px 0 0;

              .drop_box {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;
                height: 100%;
                flex-wrap: wrap;

                .showcase_item {
                  width: 33.3%;
                  margin-bottom: 10px;

                  .card_sec {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    text-align: center;

                    .card_box {
                      height: 60px;
                      width: 75px;
                      position: relative;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-size: 30px;
                      border-radius: 6px;
                      background: var(--ion-select-color);
                      margin: 0 auto;
                      cursor: grabbing;

                      &:hover {
                        background: var(--ion-btn-theme);
                      }
                    }

                    .card_text {
                      p {
                        margin: 7px 0 0;
                        line-height: 15px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

    }

    .center_side {
      width: 100%;
      height: 100%;
      position: relative;
    }

    .json_form {
      padding: 0 15px;

    }
  }
}


@keyframes scaleInOut {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.7);
  }
}


app-landing {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  height: 100vh;
  background-color: #111;
  margin: 0;
  overflow: hidden !important;
}

.loading_modal {
  .loading {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    height: 50vh;
    background-color: #111;
    margin: 0;
    overflow: hidden !important;
  }
}


.hidden {
  visibility: hidden !important;
}

.pointer {
  cursor: pointer !important;
}

.selected {
  border: 2px solid var(--ion-btn-theme) !important;

  ion-icon {
    color: var(--ion-btn-theme);
  }

}

.selected_outline {
  outline: 2px solid var(--ion-btn-theme) !important;

  .design-icon {
    ion-icon {
      color: var(--ion-btn-theme);
    }
  }

  ion-icon {
    color: var(--ion-white-text);
  }
}


.form_group {
  .color-picker-container {
    width: 40px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    ion-img {
      object-fit: contain;
      width: 100% !important;
      height: 32px !important;
      margin: auto;
    }
  }
}

app-user-comp-list {
  width: 100% !important;
}


/** Tooltip Design **/


.tooltip-example {
  text-align: center;
  padding: 0 50px;
}

.tooltip-example [tooltip] {
  display: inline-block;
  margin: 50px 20px;
  width: 180px;
  height: 50px;
  border: 1px solid gray;
  border-radius: 5px;
  line-height: 50px;
  text-align: center;
}

.ng-tooltip {
  position: absolute;
  max-width: 150px;
  font-size: 14px;
  text-align: center;
  color: #f8f8f2;
  padding: 3px 8px;
  background: var(--ion-btn-theme);
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
}

.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}

.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: var(--ion-btn-theme) transparent transparent transparent;
}

.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent var(--ion-btn-theme) transparent;
}

.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent var(--ion-btn-theme);
}

.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent var(--ion-btn-theme) transparent transparent;
}

.ng-tooltip-show {
  opacity: 1;
  background-color: var(--ion-btn-theme);
}

/** Tooltip Design **/

/* preview boxs */

.preview_box {
  .cdk-drag-placeholder {
    width: 100% !important;
    height: 100% !important;
  }
}

.preview_box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.custom-placeholder {
  background: #1e57e70b;
  border: 2px dotted var(--ion-btn-theme);
  height: 45px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ion-btn-theme);
  margin-bottom: 20px;

  &.cdk-drag-placeholder {
    top: 8px;
    position: relative;
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.03);
  border: 1px solid #e4e5ec;
  align-items: center;
  opacity: 0.8;
  display: flex;
  width: 249px;

  .sortable-item {
    display: flex;
    align-items: center;
    margin: 0;

    &::before {
      content: unset;
    }
  }
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* preview boxs */


/* App header section */

.header_container {
  width: 100%;
  height: 56px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  position: sticky;
  z-index: 99;
  background: var(--ion-backgroung-color);

  .menu_container {
    .menu_icon {
      width: 24px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      ion-icon {
        width: 100%;
        height: 100%;
        color: var(--ion-text-black);
      }
    }
  }

  .logo_container {

    .app_title p {
      font-size: 16px !important;
      color: var(--ion-text-black) !important;
    }

    .app_logo {
      height: 32px;
      width: 160px;
      overflow: hidden;

      ion-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  .action_icon_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;

    .action_item {
      width: 24px;
      height: 24px;

      .icon_sec {
        width: 100%;
        height: 100%;
        position: relative;
      }

      .count_item {
        width: 17px;
        height: 17px;
        padding: 1px 4px;
        background-color: #5fad56;
        font-size: 10px;
        text-align: center;
        position: absolute;
        border-radius: 50%;
        bottom: -4px;
        right: -6px;
        color: var(--ion-white-text) !important;
      }

      ion-icon {
        width: 100%;
        height: 100%;
        color: var(--ion-text-black);
      }
    }
  }
}

/* App header section */


/* twin or dual menu preview */

.menu_preview {
  display: flex;
  background: #fff;

  .side_bar_container {
    background: #fff;
    height: 100%;
    width: 400px;
    box-shadow: rgb(0 0 0 / 17%) 0px -15px 30px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 15px 20px 15px;

    .reverse_icon {
      flex-direction: row-reverse;
    }

    .top_side_bar {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;

      &.left_menu {
        align-items: flex-start;

        .side_bar_menu_list {
          width: 100%;

          .menu_item {
            justify-content: flex-start !important;

            span {
              text-align: left;
              line-height: 17px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
              width: 225px;
            }

            ion-icon {
              font-size: 18px;
            }
          }
        }
      }

      &.center_menu {
        align-items: center;

        .side_bar_menu_list {
          .menu_item {
            justify-content: center !important;
          }
        }
      }

      &.right_menu {
        align-items: flex-end;

        .side_bar_menu_list {
          .menu_item {
            justify-content: flex-end !important;

          }
        }
      }

      .side_bar_logo {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 999;

        ion-img {
          height: 78px;
          width: 170px;

          &::part(image) {
            object-fit: contain;
          }

          object-fit: contain;
          background-position: left;
        }
      }

      .side_bar_menu_list {
        .menu_item {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0.8rem 0.5rem;
          gap: 1rem;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 20px;
          text-transform: uppercase;

          &:first-child {
            background-color: color-mix(in srgb, var(--menu-color), transparent 90%);

            ion-icon,
            span {
              color: var(--menu-color) !important;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .right-bar {
    width: 115px;
    height: 100%;
    background-color: #fff;

    .menu-icon-holder {
      width: 100%;
      padding-top: 20px;
      padding-left: 16px;
      height: 64px;
      border-bottom: 1px solid #efefef;

      .icon-menu-one {
        background-size: 100%;
        width: 20px;
        height: 20px;

        ion-icon {
          color: var(--ion-text-black);
          font-size: 25px;
        }

      }
    }
  }
}

.bottom_tab_bar {
  position: absolute;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  height: 70px;
  background: #ffffff00;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 50px;
  overflow: hidden;

  .tab_bar_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;

    .tab_item {
      width: auto;
      text-align: center;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      padding: 20px 0 8px;
      position: relative;
      -webkit-transition: .3s;
      transition: .3s;

      &.active_item {
        &::before {
          content: "";
          width: 47%;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%);
          margin: auto 0;
          border-top: 5px solid var(--app-primary-color);
          border-radius: 0 0 60px 60px;
          -webkit-transition: .3s;
          transition: .3s;
        }

        .tab_txt {
          color: var(--app-primary-color);
        }

        .tab_icon {
          ion-icon {
            color: var(--app-primary-color);
          }
        }
      }

      .tab_icon {
        display: flex;
        align-items: center;
        justify-content: center;

        ion-icon {
          font-size: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .tab_txt {
        font-size: 10px;
        text-transform: capitalize;
        font-weight: 500;
        width: 72px;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
        padding: 0 4px;
        margin: 0;
      }
    }
  }
}

/* twin or dual menu preview */


/* copy discount code */
.link_clipboard {
  opacity: 0 !important;
  width: 100% !important;
  height: 1px !important;
  position: absolute;
}

/* copy discount code */

/* screen page */

.screen_view_sec {
  max-width: 500px;
}

.screen_container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 30px 30px;

  .screen_box {
    padding: 15px 20px 5px;
    border: 1px solid var(--ion-theme-border-color);
    width: 31.3%;
    min-height: 160px;
    background: var(--ion-select-color);
  }
}


app-screens {
  background: var(--ion-inner-bg);

  .screen_page {
    margin: 100px 30px 50px;
    height: auto;
    padding: 25px 0;
    overflow-y: auto;
    position: relative;
  }

  .left_side {
    position: absolute;
    width: 100%;
    top: 15px;
    left: 0;

    .screen_view_sec {
      ion-segment {
        flex-direction: row;
      }
    }
  }

  .right_side {
    margin-top: 60px;

    .screen_editor_sec {
      app-screen-editor {
        .section-content {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .taggle_label_icon {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            p {
              font-size: 16px;
            }

            .icon_span {
              width: 38px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-right: 10px;

              ion-icon {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

/* screen page */

/* profile page */

app-profile {
  background: var(--ion-inner-bg);

  .double_input_drop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .input_due {
      display: flex;
    }

    app-dropdown {
      width: 175px;
      margin-right: 15px;

      .ng-select {
        .ng-clear-wrapper {
          width: 31px;

          .ng-clear {
            font-size: 26px;
          }
        }
      }

      .dropdown_component_sec {
        height: 50px;
        border-radius: 10px;

        .ng-placeholder {
          font-size: 14px;
        }

        .ng-value-label {
          font-size: 14px;
        }
      }

      .dropdown_comp {
        ion-list {
          height: 50px;
          border-radius: 8px;

          ion-item {
            height: 100%;

            ion-select {
              height: 100%;

              &::part(icon) {
                transform: unset !important;
                right: 30px;
              }

              &::part(text) {
                padding-right: 40px;
              }
            }
          }
        }
      }
    }

    .input_box {
      width: 230px !important;
    }
  }

  .profile_page {
    margin-top: 85px;
    align-items: flex-start;
    overflow: scroll;

    .input_filed {
      margin-left: 5px !important;
    }

    .right {
      width: 100%;
      margin: 0 auto;
      background-color: transparent;
      align-items: flex-start;
      flex-wrap: wrap;
      max-width: 1000px;

      .card_bg {
        padding: 10px 35px 10px;
        background: var(--ion-theme-color);
        margin-top: 20px;
      }

      .login_pages {
        width: 100%;
        margin-bottom: 30px;
      }

      ion-item {
        .input_box {
          &.drop_down {
            padding: 0 15px !important;

            app-dropdown {
              width: 100%;

              .dropdown_comp {
                ion-list {
                  height: auto;
                  border: unset;

                  ion-item {
                    height: 100%;

                    ion-select {
                      height: 100%;

                      &::part(icon) {
                        transform: unset !important;
                        right: 30px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .submit_sec {
        text-align: right;

        ion-button {
          width: auto !important;
          --padding-start: 2.1em;
          --padding-end: 2.1em;
        }
      }


      .double_input {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .field {
          width: 48%;
        }
      }
    }

    .app_version_sec {
      position: absolute;
      right: 50px;
      bottom: 25px;
      color: #9b9da0;
      text-transform: uppercase;
    }
  }
}

/* profile page */

/* time zone */

ng-dropdown-panel {
  // background: var(--ion-theme-color);
  background: var(--ion-white-text);
  opacity: 5 !important;

  &.ng-dropdown-panel {
    .ng-dropdown-panel-items {
      max-height: 275px !important;

      .ng-option-child {
        .item {
          p {
            padding-left: 25px;
            font-weight: 500;
          }
        }

      }

      .ng-optgroup {
        font-weight: 800;
      }

      .ng-option-disabled {
        height: 30px;
        color: var(--ion-text-black) !important;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .ng-option-selected {
    .item {
      :is(span, p) {
        font-weight: 800;
        background: var(--ion-btn-theme);
        color: var(--ion-white-text) !important;
      }

      p {
        font-weight: 800;
        background: var(--ion-btn-theme);
        color: var(--ion-white-text) !important;
      }
    }
  }

  .item {
    p {
      padding: 10px 10px;
      margin: 0;
    }

    :is(span, p) {
      color: var(--ion-text-black) !important;
      cursor: pointer;
    }
  }
}

/* time zone */


/* Copied Toast */

.toast_bar {
  display: none;

  .confirmation {
    font-size: 11px;
    font-weight: 600;
    color: var(--ion-success-color);
    display: flex;
    opacity: 0;
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    margin-left: 12px
  }

  ion-icon {
    color: var(--ion-success-color);
    width: 14px;
    height: 14px;
    margin-right: 4px
  }
}


.toast_bar {
  &.copied {
    display: block !important;

    .confirmation {
      -webkit-animation-name: slideInOut;
      animation-name: slideInOut;
      -webkit-animation-duration: 1.5s;
      animation-duration: 1.5s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      margin-bottom: 15px;

    }
  }
}


@-webkit-keyframes slideInOut {
  0% {
    opacity: 0;
    -webkit-transform: translateY(5px);
    transform: translateY(5px)
  }

  10% {
    opacity: 0.9;
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  90% {
    opacity: 0.9;
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px)
  }
}

@keyframes slideInOut {
  0% {
    opacity: 0;
    -webkit-transform: translateY(5px);
    transform: translateY(5px)
  }

  10% {
    opacity: 0.9;
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  90% {
    opacity: 0.9;
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px)
  }
}

/* Copied Toast */


.loading_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;

  &.loading_modal {
    .loading {
      width: 50vh;
      background-color: unset !important;
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

app-collection-select,
app-auto-upload,
app-manual-upload {

  .empty-list .description,
  .empty-list .title {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center
  }

  .empty-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px auto;
    max-width: 400px;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);

    .icon-cards-info-white {
      height: 84px;

      ion-icon {
        font-size: 100px;
      }
    }

    .description {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.43;
      color: #000
    }

    .title {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.25;
      color: #000;
      margin: 24px 0 8px
    }
  }
}


// Onboarding Tour

@keyframes pulse {
  0% {
    transform: scale(.95);
    box-shadow: 0 0 0 0 rgb(39 85 201);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px transparent;
  }

  100% {
    transform: scale(.95);
    box-shadow: 0 0 0 0 transparent;
  }
}

.introjs-helperLayer {
  box-shadow: rgba(33, 33, 33, 0.8) 0px 0px 1px 2px, rgb(22 28 35 / 71%) 0px 0px 0px 5000px !important;
}

.introjs-tooltipReferenceLayer * {
  font-family: 'Montserrat', sans-serif !important;
}

.introjs-tooltip-title {
  font-weight: 600;
}

.onboarding_tour {
  background-color: var(--ion-white-text) !important;
  border: 1px solid var(--ion-white-text);
  box-shadow: unset;

  .introjs-bullets {
    ul {
      li a.active {
        background: var(--ion-btn-theme) !important;
      }
    }
  }

  .introjs-button {
    border-radius: 3px !important;
    padding: 6px 8px;
    font-size: 14px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    font-weight: 500;
    width: 70px;
    background: var(--ion-btn-theme) !important;
    text-align: center;
    border: unset;
    text-shadow: unset;
    color: var(--ion-white-text) !important;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    &.introjs-prevbutton {
      display: none;
    }

    &:hover,
    &:active,
    &:focus {
      outline: 0;
      text-decoration: none;
      border-color: var(--ion-btn-theme);
      background-color: var(--ion-btn-theme);
      color: var(--ion-white-text);
      box-shadow: unset !important;
      border: unset !important;
    }
  }

  h1,
  h5,
  div {
    color: var(--ion-btn-theme) !important;
  }

  .introjs-tooltipbuttons {
    border-top: unset !important;
  }

  .introjs-tooltiptext {
    padding: 0 20px 20px;
  }

  h5 {
    font-size: 15px !important;
    color: var(--ion-white-text) !important;
    margin: 10px 0 30px;
    line-height: 22px;
    font-weight: 400;
  }

  .introjs-skipbutton {
    display: none !important;
  }

  .tour_slide {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.introjs-hint-pulse {
  width: 25px !important;
  height: 25px !important;
  border-radius: 50% !important;
  background-color: color-mix(in srgb, var(--ion-btn-theme), transparent 20%) !important;
  animation: pulse 2s infinite !important;
}

.introjs-tooltiptext {

  h2,
  h5,
  p {
    color: var(--ion-text-black) !important;
  }
}

// Onboarding Tour


// Publish Table


@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }

  to {
    stroke-dashoffset: 113px;
  }
}


.publish_page {
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 30px;
  overflow: auto;

  app-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .refresh_sec {
    display: flex;
    align-items: center;

    .countdown {
      position: relative;
      margin: auto;
      height: 40px;
      width: 55px;
      text-align: center;
      margin-right: 15px;

      .countdown-number {
        color: white;
        display: inline-block;
        line-height: 40px;
      }

      svg {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        transform: rotateY(-180deg) rotateZ(-90deg);

        circle {
          stroke-dasharray: 113px;
          stroke-dashoffset: 0px;
          stroke-linecap: round;
          stroke-width: 2px;
          stroke: var(--ion-btn-theme);
          fill: none;
          animation: countdown 60s linear infinite forwards;
        }
      }
    }

  }

  .publish_header_sec {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    height: 70px;
    margin-bottom: 20px;

    .btn_sec {
      display: flex;
      gap: 15px;

      ion-button {
        ion-icon {
          margin-right: 20px;
        }
      }
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    background: var(--ion-theme-color);

    th {
      padding: 20px 20px 20px 20px;

      &.name {
        width: 20%;
      }

      &.version {
        width: 10%;
      }

      &.date {
        width: 15%;
      }

      &.status {
        width: 20%;
      }

      &.progress {
        width: 15%;
      }

      &.build {
        width: 10%;
      }

      &.action {
        width: 10%;
        text-align: center;
      }
    }

    td {
      padding: 15px 20px 15px 20px;
    }

    th {
      background: var(--ion-theme-color);
      color: var(--ion-white-text);
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      border-bottom: 1px solid var(--ion-theme-border-color);
    }

    td {
      background: transparent;
      color: var(--ion-white-text);
      text-align: left;
      font-size: 13px;
      font-weight: 400;
      height: 60px;

      p {
        font-size: 13px !important;
        color: var(--ion-white-text) !important;
        font-weight: 500;
      }

      .menu_icons {
        text-align: center;

        ion-icon {
          font-size: 28px;
          color: var(--ion-white-text);
        }
      }

      app-button {
        ion-button {
          width: auto;
          --background: var(--ion-btn-theme) !important;

          span {
            font-size: 13px;
          }

          ion-icon {
            font-size: 13px;
            margin-right: 10px;
          }
        }
      }

      &.cancelled,
      &.failure,
      &.error {
        p {
          color: #FF1900 !important;
          margin: 0;
          padding: 10px 20px;
          background: #ff19001a;
          border-radius: 34px;
          width: fit-content;
        }

      }

      &.configured {
        p {
          color: var(--ion-btn-theme) !important;
          margin: 0;
          padding: 10px 20px;
          background: #1e56e72e;
          border-radius: 34px;
          width: fit-content;
        }
      }

      &.ready {
        p {
          color: #FF5722 !important;
          margin: 0;
          padding: 10px 20px;
          background: #ff57222e;
          border-radius: 34px;
          width: fit-content;
        }
      }



      &.success,
      &.running {
        p {
          color: var(--ion-success-color) !important;
          margin: 0;
          padding: 10px 20px;
          background: #009a4d26;
          border-radius: 34px;
          width: fit-content;
        }

      }
    }

    .progress_bar div {
      display: flex;
      align-items: center;
      gap: 15px;

      ion-progress-bar {
        &::part(progress) {
          --progress-background: var(--ion-btn-theme);
        }
      }
    }
  }

  .no_builds {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
  }
}

// Publish Table

.unsubscribe_page.loading {
  justify-content: center !important;
  align-items: center !important;
  margin-top: 0 !important;
}

.deleted_page {
  margin-top: 0 !important;
}

.unsubscribe_modal {
  background: var(--ion-theme-color);

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 25px 50px;

    .image_container {
      ion-img {
        &::part(image) {
          width: 200px;
          height: 200px;
          object-fit: contain;
          object-position: center;
        }
      }
    }

    p {
      margin: 24px 0;
      color: #6f6f6f;
      font-weight: 400;
      font-size: 16px;
    }

    .btn_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 25px;

      app-button {
        ion-button {
          width: 100%;
          height: 100%;
          --background: var(--ion-btn-theme);

          &::part(native) {
            border-radius: 3px !important;
            min-width: 80px;
            padding: 6px 8px;
            height: 40px;
            font-size: 14px;
            letter-spacing: .5px;
            margin-right: 10px;
            text-transform: capitalize;
            font-weight: 500;
          }
        }
      }
    }


    .msg_text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
}

.delete_account {

  form {
    width: 100%;
    margin-bottom: 12px;

    ion-input {
      --highlight-color-focused: unset;
      --highlight-color-valid: unset;
      --highlight-color-invalid: unset;
      --highlight-color: unset;

      input {
        display: flex !important;
        align-items: center !important;
        justify-content: flex-start !important;
        height: 50px !important;
        background: transparent !important;
        padding: 0 20px !important;
        border-radius: 10px !important;
        width: 100% !important;
        border: 1px solid #E0E0E0 !important;
        position: relative !important;
      }
    }
  }

}

.hide_element {
  display: none !important;
}



// Admin scss

.admin-ng-option {
  p {
    color: black !important;
    font-size: 14px !important;
    padding-left: 27px !important;
  }

  span {
    color: black !important;
    font-size: 14px !important;
    padding-left: 5px !important;
  }
}

// Admin scss


.showcase_tour {
  .showcase_page.no_center_scroll {
    .center_side {
      overflow: unset !important;
    }
  }

  .show_items_preview {
    .preview_boundary {
      max-height: 750px !important;
    }
  }
}



/* Price section */

.plans_selection {
  width: 100%;
  border: 1px solid var(--border_color);
  border-radius: 0;

  ion-accordion {
    background: transparent;
    width: 100%;

    ion-item {
      background: var(--ion-theme-color);
      border-radius: 0;
      padding: 10px;
      width: 100%;

      &::part(native) {
        background: transparent;
      }
    }
  }
}

.price_container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1280px;
  margin: 80px auto 30px;

  .active_btn {
    padding: 0.8rem 3rem;
    background: var(--ion-btn-theme);
    font-size: 14px;
    font-weight: 500;
    color: var(--ion-white-text);
    text-decoration: unset;

    &.outer_line {
      background: transparent;
      border: 1px solid var(--ion-btn-theme);
    }
  }

  .price_box {
    width: 32%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    border-right: 1px dashed var(--border_color);
    min-height: 630px;
    height: 100%;
    // padding: 20px;
    position: relative;

    &:last-child {
      border-right: unset;
    }

    .head,
    .body_sec {
      width: 85%;
      border-bottom: 1px dashed var(--border_color);
      padding: 20px 0;
      height: 155px;
    }

    .body_sec {
      min-height: 145px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .footer {
      padding: 20px 0;
      min-height: 430px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: start;
      align-items: center;
      width: 100%;
      margin: 0 auto;

      .btn {
        cursor: pointer;
      }

      .detail {
        display: flex;
        align-items: center;
        gap: 18px;
        margin-bottom: 20px;
        padding: 0 20px;

        .icon {
          ion-icon {
            width: 30px;
            height: 30px;
          }
        }

        p {
          margin: 0;
          color: var(--text-light-color) !important;

        }
      }
    }

    .body_sec {
      h3 {
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 15px;

        span {
          font-size: 15px;
          font-weight: 300;
          color: var(--text-light-color) !important;
        }
      }
    }

    h2 {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    p {
      font-size: 16px;
      margin-bottom: 15px;
      line-height: 28px;
      font-weight: 500;
      color: var(--text-light-color) !important;
    }
  }

  .popular {
    position: absolute;
    top: -35px;
    right: 30%;
    padding: 12px 35px;
    // box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.4);
    color: #03e9f4;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 4px;
    font: 700 15px consolas;
    overflow: hidden;
  }

  .popular span:nth-child(1) {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to right, #171618, #03e9f4);
    animation: animate1 2s linear infinite;
  }

  @keyframes animate1 {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(100%);
    }
  }

  .popular span:nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 3px;
    background: linear-gradient(to bottom, #171618, #03e9f4);
    animation: animate2 2s linear infinite;
    animation-delay: 1s;
  }

  @keyframes animate2 {
    0% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(100%);
    }
  }

  .popular span:nth-child(3) {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to left, #171618, #03e9f4);
    animation: animate3 2s linear infinite;
  }

  @keyframes animate3 {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(-100%);
    }
  }

  .popular span:nth-child(4) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 3px;
    background: linear-gradient(to top, #171618, #03e9f4);
    animation: animate4 2s linear infinite;
    animation-delay: 1s;
  }

  @keyframes animate4 {
    0% {
      transform: translateY(100%);
    }

    100% {
      transform: translateY(-100%);
    }
  }


}

.pricing_container_box {
  flex-direction: column;
  width: 100%;

  .pricing_left {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 150px;
    margin-bottom: 25px;
    padding: 10px 35px 10px;
    background: var(--ion-theme-color);

    .user_plan_details {
      .member_details {
        .md_list {
          h4 {
            font-size: 13px;
          }

          p {
            font-size: 18px;
          }
        }
      }
    }

    .pd_left {
      .ba_head {
        align-items: center;
        gap: 30px;

        .refresh_btn {
          border: 1px solid var(--ion-btn-theme) !important;
          border-radius: 4px !important;
          background: var(--ion-btn-theme) !important;
          display: flex;
          align-items: center;
          gap: 14px;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 16px;
          text-transform: capitalize;
          width: fit-content;
          justify-content: center;
          height: 35px;
          padding: 10px 20px;
          cursor: pointer;

          p {
            margin: 0;
            font-size: 12px;
          }
        }
      }
    }
  }
}

/* Price section */

/* onboarding page */

.ai_onboard_container {
  .progress_line {

    transform: translateX(-80%);

    width: 100%;
    height: 5px;
    background: var(--app-primary-color);
    position: fixed;
    display: inline-block;
    will-change: transform;
    top: 70px;
    z-index: 2;
    transition: transform 1s cubic-bezier(0.46, 0.06, 0.26, 1.01) 0s;
  }

  .ai_onboard {
    .web_login {
      max-width: 1180px;
      margin: 0px auto;
    }

    .login_pages {
      width: 100%;
    }

    .txt_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .txt_box {
        width: 65%;
        display: flex;
        align-items: baseline;
        gap: 30px;


        h2 {
          font-weight: 600;
          font-size: 28px;
          line-height: 1.3;
          margin: 10px auto;
        }

        p {
          font-size: 18px;
          text-decoration: none;
          font-weight: 300;
          line-height: 1.3;
        }
      }

      .submit_sec {
        display: flex;
        gap: 15px;
      }
    }

    .app-icon-placeholder {
      padding: 15px 20px 15px;
      border: 1px solid var(--ion-theme-border-color);
      width: 100%;
      background: var(--ion-select-color);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 100%;
      border-radius: 8px;

      ion-icon {
        font-size: 50px;
      }
    }

    .list_item_box {
      display: flex;
      max-width: 1180px;
      margin: 0px auto;
      padding-bottom: 50px;
      width: 100%;
      flex-wrap: wrap;
      min-width: 0px;
      min-height: 0px;
      gap: 25px;
      justify-content: flex-start;

      .flex_w {
        max-width: 350px;
        height: 260px;
        width: 100%;
      }

      .item_boxs {
        // width: 100%;
        transition: transform 0.6s cubic-bezier(0, 0.89, 0.22, 1.01) 0s, opacity 0.2s ease-out 0s;
        transform: translate3d(0px, 0px, 0px) scale(1);
        opacity: 1;
        cursor: pointer;
        // max-width: 150px;
        // height: 150px;
        border: 1px solid var(--ion-theme-border-color);
        background: var(--ion-select-color);
        border-radius: 8px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

/* onboarding page */

// Admin Table

app-app-detail {
  table {
    td {
      &.status {
        app-dropdown {
          max-width: 250px !important;
          display: block;
        }
      }
    }
  }
}

// Admin Table


/* Guide Page */

app-guide {
  background: #fff;
  overflow-y: auto !important;

  .help_header {
    background-color: var(--app-primary-color);
    padding-top: 40px;

    .help-bg {
      width: 100%;
      max-width: 1170px;
      margin: 0px auto;

      .logo {
        width: auto;
        height: 60px;
        margin: 0 auto;
        display: block;
        position: relative;

        img {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          left: 0;
          right: 0;
          width: auto;
          height: 60px;
        }
      }

      h4 {
        color: #ffffff;
        font-size: 28px;
        font-weight: 700;
        text-align: center;
        margin: 30px 0px;
      }

      .guide_img {
        margin: 40px auto 0;
        display: block;

        img {
          width: 300px;
          height: auto;
        }
      }

      ion-input {
        background: #fff;
        color: #000;
        width: 50%;
        height: 45px;
        border-radius: 15px;
        margin: 0 auto;
        position: relative;
        --padding-start: 15px;
        --highlight-color-focused: unset;
        --highlight-color-valid: unset;
        --highlight-color-invalid: unset;
        --highlight-color: unset;

        input {
          color: var(--app-menu-color) !important;
        }

        ion-icon {
          position: absolute;
          top: 14px;
          right: 15px;
          font-size: 18px;
          color: var(--app-menu-color) !important;
        }
      }
    }
  }

  .help-wrap {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;

    .faq {
      width: 60%;
      margin: 50px auto;

      .faq_section {
        .faq_tabs {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;

          .group_title {
            color: #a1a1a1;
            font-size: 15px;
            font-weight: 600;
            text-transform: capitalize;
            margin: 30px 15px 15px 0px;
            cursor: pointer;

            &:hover {
              color: #875aff;
            }

            &.active {
              color: #875aff;
              font-size: 15px;
            }
          }
        }

        .faq_info {
          border: 1px solid #d6d6d6;
          border-bottom: 0;

          &:last-child {
            border-bottom: 1px solid #d6d6d6 !important;
          }

          .faq_info_action {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            ion-icon {
              width: 20px;
              height: 20px;
              margin: 0 15px;
              color: #000;
            }

            &.active {
              background: var(--ion-inner-bg) !important;
              color: #fff;

              ion-icon {
                color: #fff;
              }

              button {
                color: #fff;
              }

              &:hover {
                color: #fff;
              }
            }

            &:hover {
              color: #875aff;
            }
          }

          button {
            background: transparent;
            width: 100%;
            height: 50px;
            text-align: left;
            padding: 0px 20px;
            text-transform: capitalize;
            cursor: pointer;
            border: 0;
            color: #545454;
            font-size: 15px;
            font-weight: 500;

            &.active {
              background: #875aff !important;
              color: #fff;

              &:hover {
                color: #fff;
              }
            }

            &:hover {
              color: #875aff;
            }
          }

          ion-item {
            --border-style: 0;
            padding: 15px 0px;

            div {
              margin: 0 auto;
            }

            ion-item {
              padding: 0 !important;
            }
          }
        }
      }
    }
  }

  .back_btn {
    position: absolute;
    top: 30px;
    left: 30px;
    cursor: pointer;

    a {
      background: none;
      border: 1px solid #fff;
      color: #fff;
      font: inherit;
      letter-spacing: inherit;
      $color: #fff;
      width: 120px;
      height: 36px;
      border-radius: 30px;
      text-transform: inherit;
      transition: color 0.5s;
      display: block;
      text-align: center;
      line-height: 36px;
      text-decoration: none;

      &:hover {
        animation: halftone 1s forwards;
        background: radial-gradient(circle, $color 0.2em, transparent 0.25em) 0 0 / 1.25em 1.25em, radial-gradient(circle, $color 0.2em, transparent 0.25em) 6.25em 6.25em / 1.25em 1.25em;
        color: #875aff;
      }
    }

    @keyframes halftone {
      100% {
        background-size: 2.375em 2.375em, 0.1em 0.1em;
      }
    }
  }
}


.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 15px;
  height: 15px;
  background: rgba(255, 255, 255, 0.4);
  animation: animate 25s linear infinite;
  bottom: -150px;

}

.circles li:nth-child(1) {
  left: 25%;
  width: 50px;
  height: 50px;
  animation-delay: 2s;
}


.circles li:nth-child(2) {
  left: 10%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 3s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 15px;
  height: 15px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 30px;
  height: 30px;
  animation-delay: 2s;
  animation-duration: 5s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 15px;
  height: 15px;
  animation-delay: 1s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 70px;
  height: 70px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 90px;
  height: 90px;
  animation-delay: 5s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 20px;
  height: 20px;
  animation-delay: 3s;
  animation-duration: 6s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 5s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 90px;
  height: 90px;
  animation-delay: 0s;
  animation-duration: 6s;
}



@keyframes animate {

  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }

}


@media only screen and (max-width: 820px) {
  app-guide {
    .help-wrap {
      .faq {
        width: 80%;
      }
    }
  }
}

/* Guide Page */