@media (max-width: 767px) {
  .show-not-available-mobile {
    height: 100%;
    background: var(--ion-inner-bg);
    position: relative;
    pointer-events: none;

    .empty-list {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      margin: 0 auto;
      text-align: center;

      .title {
        span {
          font-size: 18px;
        }
      }

      .no_preview_design_icon {
        height: 115px;

        ion-icon {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media (max-width: 1300px) {

  ion-modal {

    &.collection_modal,
    &.menu_icon {
      &::part(content) {
        height: 90% !important;
      }
    }

    &#video-upload-modal {
      .video_frame {
        width: 60% !important;
        height: 215px !important;
      }

      .screen_container {
        height: 120px !important;
      }

      .screen_img {
        width: 95px !important;
        height: 95px !important;
      }
    }

  }

  app-menu {
    .dual_menu {
      .left_side {
        .inner_sec {
          padding-bottom: 90px !important;
        }
      }

      app-menu-dropper {
        .inner_sec {
          .header_sec {
            &::after {
              margin-left: -15px;
            }
          }
        }
      }

      .show_items_preview {
        margin: 0 35% 80px auto !important;
      }

    }
  }


}

@media screen and (max-device-width: 1080px) and (orientation: portrait) {

  ion-split-pane,
  ion-modal {
    display: none !important;
  }

  .show-not-available-mobile {
    height: 100%;
    background: var(--ion-inner-bg);
    position: relative;
    pointer-events: none;

    .empty-list {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      margin: 0 auto;
      text-align: center;

      .title {
        span {
          font-size: 18px;
        }
      }

      .no_preview_design_icon {
        height: 115px;

        ion-icon {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}


// on boarding tour showcase page scroll issue

@media (max-height: 720px) {
  .no_center_scroll {
    .show_items_preview {
      .preview_boundary {
        height: calc(100vh - 120px) !important;
        min-height: calc(100% - 120px) !important;
      }
    }
  }
}