:root {
    --color-1: #575757;
    --color-2: #1E56E7;
    --color-3: #999eb1;
    --color-4: #000;
    --color-5: #fff;
    --text-color: #b2b5c9;
    --background-color: #f7f8fd;
    --background-color_2: #fff;
    --background-color_3: #dcdee7;
    --border-color: #e4e5ec;
}

app-carousel {
    width: 100%;
}

app-slide {
    width: 100%;
}

.carousel_sec {
    width: 100%;
}

.preview_sec {
    width: 100%;
}

// .has_margin {
//     margin: 10px;
// }

.li {
    &.grid-rectangle-item {
        display: inline-block;
        width: 50%;
        position: relative;
        height: auto !important;
        vertical-align: top;
    }

    .add_item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        ion-button {
            width: 94%;
            height: 30px;
            font-size: 10px;
            border: 1px solid var(--app-primary-color);
            --background: transparent;
            color: var(--app-primary-color);
            --text-transform: capitalize;
            margin: 10px auto 2px;
            text-align: center;
            --box-shadow: unset;
        }
    }
}


.text_left {
    text-align: left;
}

.text_center {
    text-align: center;
}

.text_right {
    text-align: right;
}

.grid-rectangle-item1 {
    width: 50%;
}

.grid-rectangle-item2 {
    width: 33.3%;
}

.grid-rectangle-item3 {
    width: 25%;
}

.element_container {
    width: 100%;
    overflow: hidden;
    background-color: var(--background-color_2);

    &.has_margin {
        .element-header {
            .title-inner-text-real {
                padding-left: 0;
            }
        }
    }
}

.element-header {
    position: relative;

    &.view_more {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 3px;

        .title-inner-text-real {
            width: 73%;
        }

        .view_all_text {
            display: flex;
            align-items: center;
            padding: 0;
            width: 20%;
            justify-content: flex-end;

            p {
                font-size: 11px;
                text-align: center;
                font-weight: 400;
                color: var(--text-color) !important;
                margin-right: 10px;
                white-space: nowrap;
            }

            ion-icon {
                color: var(--text-color) !important;
            }
        }

    }
}

.video-element {
    &.has_margin {
        margin: 10px;

        .title-inner-text-real {
            padding-left: 0;
        }
    }

    .element-price-text {
        font-size: 14px;
        color: var(--ion-text-black);
    }
}

.video-holder {
    height: 220px;
    position: relative;

    .video-js {
        padding: 0 !important;
        width: 100% !important;
        height: 100% !important;
    }

    .video_image {
        width: 100%;
        height: 100%;
        background-color: #d0d0d0;
        position: relative;

        .thumbnail {
            width: 100%;
            height: 100%;

            &.fit {
                ion-img {
                    &::part(image) {
                        object-fit: contain;
                    }
                }
            }

            &.fill {
                ion-img {
                    &::part(image) {
                        object-fit: cover;
                    }
                }
            }

            ion-img {
                &::part(image) {
                    width: 100%;
                    height: 220px;
                    background-color: #fff;
                    object-position: center;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    .replay_icon {
        position: absolute;
        z-index: 99;
        top: 10px;
        left: 10px;

        ion-icon {
            width: 40px;
            height: 40px;
        }
    }

    .volume_icon {
        position: absolute;
        top: 10px;
        right: 10px;

        ion-icon {
            width: 40px;
            height: 40px;
        }
    }

    .control_icon {
        position: absolute;
        bottom: 10px;
        width: 90%;
        margin: 0 auto;
        left: 5%;

        ion-icon {
            width: 100%;
            height: 50px;
        }
    }

    .play_icon {
        position: absolute;
        top: 43%;
        left: 50%;
        transform: translate(-50%, -50%);

        ion-icon {
            width: 40px;
            height: 40px;
        }
    }
}

.sale-price-text {
    text-align: center;
    font-size: 11px;
    color: #FF001C !important;
    text-decoration: line-through;
    margin: 4px auto 5px;
}

.element-price-text {
    font-size: 11px;
    text-align: center;
    font-weight: 600;
    color: #252525;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 0;
    word-break: break-word;
    white-space: pre-line;
}

.element-product-text {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: var(--color-1);
    margin: 4px;
    padding-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 5px 2px;
    overflow: hidden;
    max-height: 2.5em;
    word-wrap: break-word;
    white-space: normal;


}

.title-inner-text-real {
    font-size: 14px;
    color: var(--color-1);
    word-break: break-all;
    min-height: 30px !important;
    padding-top: 6px;
    padding-right: 5px;
    padding-left: 10px;
    text-align: start;
    font-weight: 700;
    word-break: break-word;
}

.hide_border {
    .element_img.img-holder {
        border: unset;
    }

    .content-holder {
        border: unset;
    }
}

.ul {
    padding: 0;
    margin: 0;
    overflow-x: unset !important;
    // text-align: left;

    &.has_item_margin {
        // &.ul {
        //     margin-right: 0 !important;
        // }

        .li {
            margin: 5px 0 0 5px !important;
        }

    }

    &.has_item_8_margin {
        &.has_item_margin {
            &.ul {
                margin-right: 5px;
            }
        }


        .li {
            width: 23.3% !important;

            .add_item {
                ion-button {
                    font-size: 8px;
                }
            }
        }
    }

    // medium
    &.has_item_6_margin {
        &.has_item_margin {
            &.ul {
                margin-right: 5px;
            }
        }

        .li {
            width: 31.6% !important;
        }
    }

    // large
    &.has_item_4_margin {
        &.has_item_margin {
            &.ul {
                margin-right: 5px;
            }
        }

        .li {
            width: 48% !important;
        }
    }
}



.element_img {
    &.img-holder {
        height: auto;
        width: 100%;
        background: var(--background-color);
        border: 1px solid var(--border-color);
        position: relative;

        &::before {
            content: "";
            display: block;
            padding-bottom: 100%;
        }
    }

    .grid-combined-shape {
        // position: relative;
        // top: unset;
        // left: unset;
        // width: 100%;
        // object-fit: cover;
        // margin: 0 !important;
        // height: 100%;
        // background-size: contain;

        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        object-fit: cover;
        margin: 0 !important;
        height: 100%;
        background-size: cover;
        transform: translate(-50%, -50%);

    }
}

.content-holder {
    height: auto !important;
    width: 100%;
    border: 1px solid var(--border-color);
    padding-top: 5px;
    padding-bottom: 5px;

    &.icon_holder {
        height: 70px !important;
        display: flex;
        align-items: center;
        padding-left: 20px;
    }
}


.indicator-holder {
    text-align: center;
    width: 100%;

    .item {
        width: 5px;
        display: inline-block;
        height: 5px;
        border-radius: 50%;
        background: #c5c5c5;
        margin-right: 5px;

        &:first-child {
            background: var(--color-2) !important;
        }
    }
}

/* this circle gallery */

.circle_layout {

    &.has_margin {
        padding: 10px;
        width: 97%;
    }

    .element_img {
        &.img-holder {
            width: 72px;
            height: 72px;
            border: 1px solid #d7d9e4;
            background-color: #f7f8fd;
            border-radius: 50%;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            margin: 0 auto;
            overflow: hidden;
        }
    }



    .content-holder {
        border: unset;
    }

    .ul {
        width: 100%;
        padding: 0 !important;
        margin: 0;
        list-style-type: none;
        white-space: nowrap;
        overflow-x: auto;
        text-align: left;

        &.has_item_margin {
            .li {
                margin: 0 5px 0 0 !important;
            }
        }
    }

    .has_item_6_margin {
        .li {
            width: 25% !important;
        }
    }

    .grid-rectangle-item {
        display: inline-block;
        min-height: 40px;
        width: 85px;
        padding: 8px 0 8px;
        vertical-align: top;
    }
}

/* this circle gallery */

/* slider_layout */

.slider_layout {

    &.has_margin {
        padding: 10px;
    }

    .li {
        margin-right: 5px;

        &.grid-rectangle-item {
            width: 237px !important;
            height: auto !important;
            display: inline-block;
            list-style-type: none;
            vertical-align: middle;
            position: relative;
        }
    }

    .element_img.img-holder {
        border: unset;

        &::before {
            padding-bottom: 0;
        }
    }

    .content-holder {
        border: unset;
    }

    &.title_hidden {
        .content-holder {
            display: none;
        }
    }

    .ul {
        height: auto;
        list-style-type: none;
        white-space: nowrap;
        overflow-x: auto;
        padding: 0;
        margin: 0;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        overflow-x: clip !important;
    }

    .has_item_margin {
        .li {
            margin: 0 10px 0 0 !important;
        }
    }

    &.title_inner {
        .element_img {
            &.img-holder {
                background: #808080;

                &::before {
                    padding-bottom: 0;
                    background: rgba(0, 0, 0, 0.4);
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 99;
                }
            }
        }

        .content-holder {
            position: absolute;
            top: 50%;
            width: 100%;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            border: unset;
            z-index: 99;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .element-product-text,
        .element-price-text {
            color: var(--color-5);
        }
    }

    .element-product-text {
        // color: var(--color-2);
        color: var(--ion-text-black);
    }

    .element_img {
        &.img-holder {
            width: 100%;
            height: 118px !important;

        }
    }

}

/* slider_layout */

/* Slideshow_layout */

.slideshow_layout {

    &.has_margin {
        padding: 10px;
    }


    .li {

        .btn-cart {
            width: 94%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 7px;
            margin: 0 auto;
            font-size: 14px;

            span {
                background: #e8e8e8;
                width: 0.6em;
                height: 0.6em;
                display: block;
                margin: 0 0.2em;
                border-radius: 100px;
                margin-top: 0;
            }
        }

        &.grid-rectangle-item {
            width: 100% !important;
            height: 100%;
            min-height: 151px;
            display: inline-block;
            list-style-type: none;
            vertical-align: middle;
            position: relative;
        }

        .element_img.img-holder {
            &::before {
                padding-bottom: 0;
            }

        }

        &.view_square {
            .element_img {
                &.img-holder {
                    height: 370px !important;
                }
            }
        }

        &.view_vertical {
            .element_img {
                &.img-holder {
                    height: 420px !important;
                }
            }

        }

        &.view_horizontal {
            .element_img {
                &.img-holder {
                    height: 205px !important;
                }
            }
        }
    }

    .element_img.img-holder {
        border: unset;
        margin-bottom: 10px;
    }

    .content-holder {
        border: unset;
    }

    &.title_hidden {
        .content-holder {
            display: none;
        }
    }

    .ul {
        height: auto;
        list-style-type: none;
        white-space: nowrap;
        overflow-x: auto;
        padding: 0;
        margin: 0;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        overflow-x: clip !important;
    }


    .has_item_margin {
        .li {
            margin: 0 10px 0 0 !important;
        }
    }

    &.title_inner {
        .element_img {
            &.img-holder {
                background: #80808022;

                &::before {
                    padding-bottom: 0;
                    background: rgb(0 0 0 / 40%);
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 99;

                }
            }
        }

        .content-holder {
            position: absolute;
            top: 50%;
            width: 100%;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 999;

        }

        .element-product-text,
        .element-price-text {
            color: var(--color-5);
        }
    }

    .element-product-text {
        color: var(--color-4);
    }



}

/* Slideshow_layout */

/* grid_layout */

.grid_layout {



    .element-product-text {
        height: 2.5em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }


    ul {
        text-align: left;
        display: flex;
        flex-wrap: wrap;

        .li {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .content-holder {
                max-height: 130px !important;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100% !important;

                .inner_txt {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;

                    .element-price-text {
                        margin: 10px 0;
                    }

                }
            }
        }

        &.has_item_8_margin {
            .li {
                width: 25% !important;
            }

            &.has_item_margin {
                li {
                    width: 23.3% !important;
                }
            }
        }

        &.has_item_6_margin {
            .li {
                width: 33% !important;
            }

            &.has_item_margin {
                li {
                    width: 31.6% !important;
                }
            }
        }

        &.has_item_4_margin {
            .li {
                width: 50% !important;
            }

            &.has_item_margin {
                li {
                    width: 48% !important;
                }
            }
        }

    }

    &.has_margin {
        padding: 10px;
    }


    .grid-rectangle-item {
        &.view_vertical {
            .img-holder {
                height: auto;
            }
        }
    }

    &.size_small {

        li {
            &.grid-rectangle-item {
                // width: 25% !important;
            }

            // &.view_square {
            //     .img-holder {
            //         height: 87px;
            //     }
            // }

            &.view_vertical {
                .img-holder {
                    height: 100px;
                }
            }

            &.view_horizontal {
                .img-holder {
                    height: 60px;
                }
            }
        }

    }

    &.size_medium {

        li {
            &.grid-rectangle-item {
                // width: 33.3% !important;
            }

            // &.view_square {
            //     .img-holder {
            //         height: 116px;
            //     }
            // }

            &.view_vertical {
                .img-holder {
                    height: 130px;
                }
            }

            &.view_horizontal {
                .img-holder {
                    height: 70px;
                }
            }
        }

    }

    &.size_large {

        li {
            // &.view_square {
            //     .img-holder {
            //         height: 168px;
            //     }
            // }

            &.view_vertical {
                .img-holder {
                    height: 200px;
                }
            }

            &.view_horizontal {
                .img-holder {
                    height: 120px;
                }
            }
        }

    }

}

/* grid_layout */

/*  carousel_layout */

.carousel_layout {

    .ele_main_container {
        white-space: nowrap;
        overflow: hidden !important;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;

        &.single_list {
            .ul {
                margin-left: 0 !important;

                .li {
                    margin: 0 !important;
                }
            }
        }
    }



    &.has_margin {
        padding: 10px;

        .element-header {
            margin: 0;
        }
    }

    .has_item_margin {
        .li {
            margin-right: 11px !important;
        }
    }

    &.size_small {

        .has_margin {
            .ul {
                margin-left: -69px !important;
            }

            .ul.has_item_margin {
                margin-left: -80px !important;
            }
        }

        .ul {
            margin-left: -60px;

            &.has_item_margin {
                margin-left: -69px !important;
            }
        }



        .has_item_8_margin {
            .li {
                display: inline-block;
                min-height: 40px;
                width: 140px !important;
                padding: 8px 0;
                margin-top: 17px !important;
                margin-right: 12px !important;

                &.view_vertical {
                    margin-top: 35px !important;

                    &.active_slide {
                        margin-top: 0 !important;
                    }
                }

                // &.active_slide {
                //     width: 238px !important;
                //     margin-top: 0 !important;
                // }
            }

        }

        .view_square {
            &.active_slide {
                margin-top: 0 !important;
                width: 171px !important;

                // .element_img {
                //     &.img-holder {
                //         height: 171px !important;
                //     }
                // }
            }
        }

        .view_vertical {
            margin-top: 40px !important;

            .element_img {
                &.img-holder {
                    height: 180px !important;
                }
            }

            &.active_slide {
                margin-top: 0 !important;
                width: 171px !important;

                .element_img {
                    &.img-holder {
                        height: 249px !important;
                    }
                }
            }


        }

        .view_horizontal {
            margin-top: 25px !important;

            .element_img {
                &.img-holder {
                    height: 90px !important;
                }
            }

            &.active_slide {
                margin-top: 0 !important;
                width: 171px !important;

                .element_img {
                    &.img-holder {
                        height: 124px !important;
                    }
                }
            }
        }

    }

    &.size_medium {

        .has_margin {
            .ul {
                margin-left: -112px !important;
            }

            .ul.has_item_margin {
                margin-left: -125px !important;
            }
        }

        .single_list {
            .ul {
                margin-left: 0 !important;

                &.has_item_margin {
                    margin-left: 0 !important;
                }
            }
        }

        .ul {
            margin-left: -104px;

            &.has_item_margin {
                margin-left: -112px !important;
            }
        }



        .has_item_6_margin {
            .li {
                display: inline-block;
                min-height: 40px;
                width: 168px !important;
                padding: 8px 0;
                margin-right: 12px !important;
            }
        }


        .view_square {
            margin-top: 18px !important;

            &.active_slide {
                margin-top: 0 !important;
                width: 202px !important;

                // .element_img {
                //     &.img-holder {
                //         height: 197px !important;
                //     }
                // }
            }
        }

        .view_vertical {
            margin-top: 40px !important;

            .element_img {
                &.img-holder {
                    height: 216px;
                }
            }

            &.active_slide {
                margin-top: 0 !important;
                width: 202px !important;

                .element_img {
                    &.img-holder {
                        height: 299px !important;
                    }
                }
            }
        }

        .view_horizontal {
            margin-top: 25px !important;

            .element_img {
                &.img-holder {
                    width: 168px;
                    height: 111px;
                }
            }

            &.active_slide {
                margin-top: 0 !important;
                width: 202px !important;

                .element_img {
                    &.img-holder {
                        height: 156px !important;
                        width: 100%;
                    }
                }
            }
        }

    }

    &.size_large {

        .has_margin {
            .ul.has_item_margin {
                margin-left: -163px !important;
            }

            .ul {
                margin-left: -153px !important;
            }
        }

        .ul {
            margin-left: -143px !important;

            &.has_item_margin {
                margin-left: -155px !important;
            }
        }

        .has_item_4_margin {
            .li {
                display: inline-block;
                min-height: 40px;
                width: 188px !important;
                height: 188px;
                padding: 8px 0;
                margin-right: 12px !important;

                // &.active_slide {
                //     width: 238px !important;
                //     margin-top: 0 !important;
                // }
            }


        }

        .view_square {
            margin-top: 25px !important;

            &.active_slide {
                margin-top: 0 !important;
                width: 245px !important;

                // .element_img {
                //     &.img-holder {
                //         height: 233px;
                //     }
                // }
            }
        }

        .view_vertical {
            margin-top: 40px !important;

            .element_img {
                &.img-holder {
                    height: 252px !important;
                }
            }

            &.active_slide {
                margin-top: 0 !important;
                width: 245px !important;

                .element_img {
                    &.img-holder {
                        height: 349px !important;

                    }
                }
            }


        }

        .view_horizontal {
            margin-top: 25px !important;

            .element_img {
                &.img-holder {
                    height: 132px !important;
                }
            }

            &.active_slide {
                margin-top: 0 !important;
                width: 245px !important;

                .element_img {
                    &.img-holder {
                        height: 188px !important;

                    }
                }
            }
        }
    }

}

/*  carousel_layout */

/*  gallery_layout */

.gallery_layout,
.recently_viewed_layout {

    &.has_margin {
        padding: 10px;
    }

    .element_img {
        &.img-holder {
            border: 1px solid #d7d9e4;
            background-color: #f7f8fd;
            margin: 0;
        }
    }

    .content-holder {
        border: unset;
    }

    .ul {
        width: 100%;
        padding: 0 !important;
        margin: 0;
        list-style-type: none;
        white-space: nowrap;
        overflow-x: clip !important;

        &.has_item_margin {
            .li {
                margin: 0 5px 0 0 !important;
            }
        }
    }

    .li {
        &.grid-rectangle-item {
            display: inline-block;
            min-height: 40px;
            width: 112px !important;
            padding: 8px 0;
            vertical-align: top;
        }
    }

    &.size_small {

        .element_img {
            &.img-holder {
                width: 100px;
                height: 100px;
            }
        }

        .li {
            &.grid-rectangle-item {
                width: 112px !important;
            }
        }

        .view_vertical {
            .element_img {
                &.img-holder {
                    height: 142px;
                }
            }
        }

        .view_horizontal {
            .element_img {
                &.img-holder {
                    height: 70px;
                }
            }
        }
    }

    &.size_medium {

        .element_img {
            &.img-holder {
                width: 128px;
                height: 128px;
            }
        }

        .li {
            &.grid-rectangle-item {
                width: 140px !important;
            }
        }

        .view_vertical {
            .element_img {
                &.img-holder {
                    height: 182px;
                }
            }
        }

        .view_horizontal {
            .element_img {
                &.img-holder {
                    height: 82px;
                }
            }
        }
    }

    &.size_large {

        .element_img {
            &.img-holder {
                width: 148px;
                height: 148px;
            }
        }

        .li {
            &.grid-rectangle-item {
                width: 160px !important;
            }
        }

        .view_vertical {
            .element_img {
                &.img-holder {
                    height: 210px;
                }
            }
        }

        .view_horizontal {
            .element_img {
                &.img-holder {
                    height: 98px;
                }
            }
        }
    }

}

/* this  gallery_layout */

/* single image  */

.image_layout {

    .li {
        &.grid-rectangle-item {
            width: 100% !important;
            height: 100%;
            min-height: 151px;
            display: inline-block;
            list-style-type: none;
            vertical-align: middle;
            position: relative;
        }


        &.view_square {
            // .element_img {
            //     &.img-holder {
            //         height: 370px !important;
            //     }
            // }
        }

        &.view_vertical {
            .element_img {
                &.img-holder {
                    height: 420px !important;
                }
            }

        }

        &.view_horizontal {
            .element_img {
                &.img-holder {
                    height: 250px !important;
                }
            }
        }
    }

    .element_img.img-holder {
        border: unset;
    }

    .content-holder {
        border: unset;
    }

    &.title_hidden {
        .content-holder {
            display: none;
        }
    }

    .ul {
        height: auto;
        list-style-type: none;
        white-space: nowrap;
        overflow-x: auto;
        padding: 0;
        margin: 0;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;

        display: flex;
        flex-direction: column;
    }

    &.has_margin {
        padding: 10px;
    }

    .has_item_margin {
        .li {
            margin: 0 0 20px 0 !important;

            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }

    &.title_inner {
        .view_vertical {
            .element_img {
                &.img-holder {
                    &::before {
                        padding: 0 !important;
                    }
                }
            }
        }

        .view_horizontal {
            .element_img {
                &.img-holder {
                    &::before {
                        padding: 0 !important;
                    }
                }
            }
        }

        .element_img {
            &.img-holder {
                background: #80808022;

                &::before {
                    background: rgb(0 0 0 / 18%);
                    position: relative;
                    z-index: 9;
                    height: 100%;
                    // padding: 0;
                }
            }
        }

        .content-holder {
            position: absolute;
            top: 50%;
            width: 100%;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 99;
        }

        .element-product-text,
        .element-price-text {
            color: var(--color-5);
        }
    }

    .element-product-text {
        color: var(--ion-text-black);
    }



}

/* single image */


/* Search bar component */

.search-bar-element {
    margin: 10px 10px;

    .search-inner-rectangle {
        width: 100%;
        height: 40px;
        text-align: center;
        border: 1px solid var(--text-color);
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 0 20px;

        &.square {
            border-radius: 0;
        }

        &.curve {
            border-radius: 5px;
        }

        &.round {
            border-radius: 20px;
        }

        .search-inner-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: left;
            gap: 15px;

            ion-icon,
            span {
                color: var(--text-color);
            }
        }
    }
}

/* Search bar component */

.title_component {
    width: 100%;
    height: 100%;
    min-height: 30px;
    word-break: break-all;
    margin: 0 auto;
    background-color: transparent;
    // display: flex;
    // align-items: center;
    padding: 10px;

    h6 {
        font-size: 14px;
        color: var(--color-1);
        word-break: break-all;
        font-weight: 700;
        word-break: break-word;
        margin: 0;
    }
}

/* Search bar component */

/* discount design  */

.discount_layout {

    &.has_margin {
        padding: 10px;

        .element-header {
            margin: 0;
        }
    }

    .element-price-text {
        font-size: 14px;
        color: var(--ion-text-black);
    }

    .li {
        &.grid-rectangle-item {
            width: 100% !important;
            height: 100%;
            min-height: 151px;
            display: inline-block;
            list-style-type: none;
            vertical-align: middle;
            position: relative;
        }

        // .element_img.img-holder {
        //     &::before {
        //         padding-bottom: 0;
        //     }
        // }

        &.view_square {
            // .element_img {
            //     &.img-holder {
            //         height: 370px !important;
            //     }
            // }
        }

        &.view_vertical {
            .element_img {
                &.img-holder {
                    height: 420px !important;
                }
            }

        }

        &.view_horizontal {
            .element_img {
                &.img-holder {
                    height: 250px !important;
                }
            }
        }
    }

    .element_img.img-holder {
        border: unset;
    }

    .content-holder {
        border: unset;
    }

    &.title_hidden {
        .content-holder {
            display: none;
        }
    }

    .ul {
        height: auto;
        list-style-type: none;
        white-space: nowrap;
        overflow-x: auto;
        padding: 0;
        margin: 0;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
    }

    .has_margin {
        margin: 10px;
    }

    .has_item_margin {
        .li {
            margin: 0 10px 0 0 !important;
        }
    }

    &.title_inner {
        .element_img {
            &.img-holder {
                background: #80808022;

                &::before {
                    // padding-bottom: 0;
                    background: rgb(0 0 0 / 40%);
                    width: 100%;
                    height: 100%;
                    position: relative;
                    z-index: 99;

                }
            }
        }

        .content-holder {
            position: absolute;
            top: 50%;
            width: 100%;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 99;

            .element-price-text {
                font-size: 14px;
                color: var(--color-5) !important;
            }

        }
    }

    .element-product-text {
        color: var(--color-2);
    }



}

/* discount design */

/* Separator Element  */

.separator-element {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Separator Element  */


/* Countdown Timer */

.countdown_container {
    .title-inner-text-real {
        text-align: center;
        font-size: 15px;
    }

    &.has_margin {
        padding: 10px;
    }

    .content-holder {
        border: unset;

        .element-price-text {
            font-size: 15px !important;
            color: var(--ion-text-black);
            letter-spacing: 0.5px;
        }
    }
}

.counter_container {
    position: relative;
    height: 100%;

    .promotion_image {
        height: auto;

        &.square {
            height: 375px;
        }

        &.vertical {
            height: 450px;
        }

        &.horizontal {
            height: 250px;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}

.countdown_timer {
    .countdown_timer_sec {
        font-weight: 100;
        text-align: center;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        gap: 10px;
        border-radius: 5px;
        border: 1px solid var(--border-color);
        margin: 15px auto 8px;
        padding: 15px;

        .item {
            width: 100%;
            // border-radius: 3px;
            background: transparent;
            position: relative;

            &::after {
                content: ":";
                position: absolute;
                right: -6px;
                top: 0;
                font-weight: 700;
                font-size: 24px;
                color: var(--countdown_timer_color);
                line-height: 30px;

            }

            &:last-child {
                &::after {
                    content: unset;
                }
            }

            .measurements-number {
                display: block;
                color: var(--countdown_timer_color);
                font-weight: 700;
                font-size: 28px;
                padding: 0 10px;
                margin: 0;
                padding-bottom: 0;

            }

            .measurements-text {
                font-size: 12px;
                color: var(--countdown_timer_color);
                margin: 0;
            }
        }
    }



}

/* Countdown Timer */